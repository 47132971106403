<template>
   <div style='position: relative;'>
     <canvas :id="chartdata.chartName"  height="300" ></canvas>
   </div>
</template>

<script>

import Chart from "chart.js"
import { onMounted , computed , watch } from "vue"

export default {
    
    props: {
        chartdata:{type: Object, default: () => { return {} } },

    },
    setup(props){
         
        var chart = null
        var chart_data = {}

        const chartConstructor = () => {

                let id_name = props.chartdata.chartName ?? 'chart';// "abc"+Math.floor(Math.random() * 10);  
                const chartElement = document.querySelector("#"+id_name);
                
                
                // const legend_postition = props.chartdata.legend_postition ?? 'top'
                const labels = props.chartdata.labels ?? ['no label']
                const values = props.chartdata.values ?? [0,0,0,0,0,0]
                const chart_title = props.chartdata.title ?? '-'
                // const colors = props.chartdata.colors ?? []


                chart_data = {
                    type: 'line',
                    data: {
                        labels: labels,
                        datasets: [{
                            label: '',
                            data: values,
                            fill: true,
                            borderColor: "#FEEFF0",
                            backgroundColor : "#FEEFF0",
                            pointBackgroundColor:"#F5515F",
                            tension: 0.1
                        }]
                    },
                    
                    options: {
                        
                        
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    drawBorder:false,
                                    display:false
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    display:false
                                },
                                ticks: {
                                    callback: (value)=>  { return ((props.chartdata.valueType == 'currency') ?  '£':'') + value; }
                                }   

                            }]
                        },
                    //     tooltips: {
                    //     callbacks: {
                    //         label: function(tooltipItem, data) {
                    //         return data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] + '%';
                    //         }
                    //         }
                    //     },
                        legend: { 
                            display: false,
                    //         position:legend_postition,
                    //         labels: {
                    //           boxWidth:12,
                    //           boxHeight:15,
                    //           fontStyle: "bold",
                            }
                        
                    //      },
                        
                    }
                };
                if (chart_title !== '-')
                {
                    chart_data.options.title = {
                                text:chart_title,
                                display: true,
                            }
                }
                chart = new Chart(chartElement, chart_data);
            
        }

        watch(props.chartdata, (newValue) => {
            console.log({val:newValue.values});
            
            
            chart.data.labels = newValue.labels ;
            chart.data.datasets[0].data = newValue.values ;
            chart.update();
        })

        const setup = ()=>{
            chartConstructor();
        }

        onMounted(setup);

        const chart_name = computed(() => props.chart_data.chart_name ?? '-')

        return {chart_name}

    }

}
</script>
