<template>
<section style=''>
  
  <section class='flex gap-20 page-padding-sides mt-20' >
    
    <data-viewer title="Total Users" icon="icn_user.svg" :value=total_users></data-viewer>
    <data-viewer title="Total Venues" icon="icn_venue.svg" :value=total_venues ></data-viewer>
    <data-viewer title="Total Orders" icon="icn_receipt.svg" :value=total_orders ></data-viewer>
    <data-viewer title="Total Stamps"></data-viewer>
    
  </section> 

  <!-- <section class='page-padding-sides mt-20' > -->
  <panel style='max-width:1200px'>
    <div class='flex-11auto'>
      <div class='fs-18'>Number of users</div>
      <line-chart :chart_data=chart_data_users_device></line-chart>
    </div>
  </panel>
  <!-- </section> -->
</section>
</template>

<script>

import LineChart from '@/components/elements/LineChart.vue'

export default {
  components: {
    LineChart
  },
  data(){
    return{
      total_users:0,
      total_venues:0,
      total_orders:0,
      chart_data_users_device: {}
    }
  },
  mounted(){

    this.getPageData('user-count'); 
    this.getPageData('venue-count'); 
    this.getPageData('order-count');
    this.getUserPeriodicData();
   
  },
  methods:{
    async getPageData(kind){
      let result = await this.$store.dispatch('requests/sendRequest', {
        endpoint:kind,
        params:{ }             
      });

      switch (kind) {
        case 'order-count':this.total_orders = result.order_count ? parseInt(result.order_count):0; break;
        case 'user-count':this.total_users = result.user_count ? parseInt(result.user_count):0; break;
        case 'venue-count':this.total_venues = result.venue_count ? parseInt(result.venue_count):0; break;
      }
    },
    async getUserPeriodicData(){
      let result = await this.$store.dispatch('requests/sendRequest', {
        endpoint:'user-count-periodic',
        params:{ }             
      });
      
      var months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
      let chart_data_labels = [], chart_data_values = [];
      let result_data = result.list ?? [];


      result_data.forEach(element => {
        let month = element.month ?? 1;
        let year = element.year ?? 1;
        let count = element.count ?? 1;
        chart_data_labels.push(months[month-1]+" "+Math.round(year));
        chart_data_values.push(count);
      });
      this.chart_data_users_device = {'labels':chart_data_labels, 'values':[chart_data_values]};

    }
    
  }
}
</script>

<style scoped>

</style>