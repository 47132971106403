

import { useStore } from 'vuex'
import functions from '../../functions'
import { useRoute } from 'vue-router'

export default function useEntityHook(state) {
    const store = useStore()
    const route = useRoute()
    let pageKind = route.path.split('/')[2]


    
    let activeVenueID = 0

    state.table_lines = []
    state.showCountry = {list:['All'], status:'All', index:0}
    state.showStatus = 0
    state.sortBy = 0
    state.timeFilter = {
        showList:[], fromIndex:0, toIndex:0, list:[]
    }
    state.totalVenues = 0
    state.arrayVenues = []
    state.subscriptionsObj = {}
    state.subInfo = [],
    state.subShowPanel = ''
    state.addSubscriptionPanel = {}
    state.trialEndDate = new Date()
    state.pageLoading = true
    state.panelData = {}
    state.savingStatus = ''

    const generateLaunchStartEnd = () => {
        let now = new Date()
        let month = now.getMonth()+1
        let year = now.getFullYear()

        let monthArray = functions.getMonthArray()
        let list = []
        while (year>=2017){
            list.push({str:`${monthArray[month-1]} ${year}`, year:year, month:month>9 ? month: '0'+month})
            month --
            if (month == 0) {year --; month=12}
        }

        state.timeFilter.list = list
        state.timeFilter.showList = list.map( (line) => { return line.str})
        state.timeFilter.fromIndex = state.timeFilter.showList.length-1
        state.timeFilter.toIndex = 0
    } 
    generateLaunchStartEnd()
          

    let stripeSubscription = {}
    // ***** actions

    const panelButtonPressed = async (type) => {
        let checksOK = false
        let sendingObj = { method:'post' }

        if (type === 'edit-type'){
            checksOK = true 
            sendingObj.endpoint = 'subscriptions/edit-stripe-data'
            sendingObj.venue_id = state.addSubscriptionPanel.venueID
            sendingObj.embargo_sub_type = state.addSubscriptionPanel.embargoSubsctiptionType
        }
        if (type === 'upgrade'){
            checksOK = true 
            sendingObj.endpoint = 'subscriptions/edit-stripe-data'
            sendingObj.venue_id = state.addSubscriptionPanel.venueID
            sendingObj.embargo_sub_upgrade_type = state.addSubscriptionPanel.embargoSubsctiptionUpgradeIndex
        }


        if (checksOK){
            
            state.savingStatus = 'editing subscription type, please wait ...'
            await store.dispatch('requests/sendApiRequest', sendingObj); 
            getData()

        }

        
    }

    const lineClicked = (index) => {
        let venueID = state.table_lines[index][0]
        
        showSubscriptionDetail(venueID)
        activeVenueID = venueID
    }

    const showSubscriptionDetail = (venueID) => {
        if (venueID == 0) return
        state.subShowPanel = ''
        state.savingStatus = ''
  
        
        const venue = state.arrayVenues.find(v => {
            return v.id == venueID
        }) 
        
    
        
     
        var today = new Date()

        let prices = getPriceIds(venue.country || 'United Kingdom' )

        let subData = venueID in stripeSubscription?.subscription_obj ? stripeSubscription?.subscription_obj[venueID] : {}
        
        let stripeStatus = subData?.status  || '-';
        let trialEnd = subData?.trial_end || 0
        

        
        state.addSubscriptionPanel.prices  = prices
        state.addSubscriptionPanel.subscriptions = prices.map( row => {return row[0]})
        state.addSubscriptionPanel.trialEndDate =  new Date(today.setDate(today.getDate() + 30))
        state.addSubscriptionPanel.email = '';
        state.addSubscriptionPanel.sub_id = state.subscriptionsObj[venueID]?.subscription || '';
        state.addSubscriptionPanel.error = ''; 
        state.addSubscriptionPanel.venueID  = venueID
        state.addSubscriptionPanel.cusID  = ''
        state.addSubscriptionPanel.subscriptionIndex = -1
        state.addSubscriptionPanel.status = stripeStatus
        state.addSubscriptionPanel.trial_start = state.subscriptionsObj[venueID]?.trial_start || '';
        state.addSubscriptionPanel.trial_end = state.subscriptionsObj[venueID]?.trial_end || '';
        state.addSubscriptionPanel.embargoSubsctiptionType = state.subscriptionsObj[venueID]?.subscription_type || 0;
        state.addSubscriptionPanel.embargoSubsctiptionUpgradeIndex = state.subscriptionsObj[venueID]?.subscription_override || 0;

        let embargoSubType = state.addSubscriptionPanel.embargoSubsctiptionType == 1 ? 'Beta':'Full'
        state.panelData.infos = [];
        state.panelData.infos.push(['Vanue Name',venue.name])
        state.panelData.infos.push(['Vanue ID',venueID])
        state.panelData.infos.push(['Subscription Type',embargoSubType])
        state.panelData.infos.push(['Venue Live Status', venue.active ? 'Live':'Not Live' ])
        
        state.panelData.infos.push([])
        state.panelData.infos.push(['Subscription Name',state.subscriptionsObj[venueID]?.plan_name || '-'])
        state.panelData.infos.push(['Stripe Customer',state.subscriptionsObj[venueID]?.customer || '-'])
        state.panelData.infos.push(['Stripe Subscription',state.subscriptionsObj[venueID]?.subscription || '-'])
        state.panelData.infos.push(['Stripe Status',stripeStatus])
        if (trialEnd>0) state.panelData.infos.push(['Trial End',functions.timestampToDate(trialEnd)['descriptiveDate']])  


        state.panelData.subscription = state.subscriptionsObj[venueID]?.subscription || ''
    }

    const panelMainButtonClicked = (kind) => {
        state.subShowPanel = kind
    } 
 

    const addSubscriptoinFindPriceID = (priceName) => {
        
        state.addSubscriptionPanel.subscriptionIndex =  priceName.index
        state.addSubscriptionPanel.prices.forEach( row => {
            if (row[0] == priceName.value) { 
                state.addSubscriptionPanel.priceID = row[1]
                state.addSubscriptionPanel.plan_name = row[0]
            }
        })
    }

    const chanegState = (kind, value) => {
    
        
        switch (kind) {
            case 'add-sub-venue-email':state.addSubscriptionPanel.email = value; break;
            case 'add-sub-subscription': addSubscriptoinFindPriceID(value); break;
            case 'add-sub-trial': state.addSubscriptionPanel.trialEndDate = value; break;
            case 'include-in-sub-cus-id': state.addSubscriptionPanel.cusID = value; break; 
            case 'edit-type': state.addSubscriptionPanel.embargoSubsctiptionType = value.index; break; 
            case 'upgrade': state.addSubscriptionPanel.embargoSubsctiptionUpgradeIndex = value.index; break; 
            
        }
        
    } 

    const refreshSubscriptions = async () => {
        state.pageLoading = true

        await store.dispatch('requests/sendApiRequest', {
            endpoint:'subscriptions/refresh-stripe',
            method:'post',
            where:[]
        }); 

        getData()
  
    }


    const parseSubscriptions = (subscriptions) => {
        const subObj = {}
        subscriptions.forEach( sub => {
            subObj[sub.venue_id] = sub
        });

        state.subscriptionsObj  = subObj
        console.log({b:state.subscriptionsObj});
        
    }

    const csvClicked = () => {
        let csv =  '';//this.header_titles.join(',')+'\n';

        state.table_lines.forEach((row) => {
            csv += row.join(',');
            csv += "\n";
        });
  
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'embargo.csv';
      anchor.click();
        
    }

    const analyseTableData = () => {

        let arrayTable = [], countries = {}

        state.arrayVenues.forEach(line => {
            let subscriptionName = '-', status = ''
            let venueID = line.id

            let filterCountry = state.showCountry.status
            let subscriptionEmbargoType = '', payment = '-'
              
            if (state.subscriptionsObj[venueID]){
                let subObj = state.subscriptionsObj[venueID]
                subscriptionName = subObj.plan_name
                if (subObj.subscription_type==1) subscriptionEmbargoType = `β`
                else subscriptionEmbargoType = `F`

                if (subObj.sub_payment && subObj.sub_payment.length>0 )  payment = 'sub payment'   
                else  if (subObj.cus_payment && subObj.cus_payment.length>0 )  payment = 'cus payment' 
            
            }
            else subscriptionEmbargoType = `F`

            let subData = venueID in stripeSubscription?.subscription_obj ? stripeSubscription?.subscription_obj[venueID] : {}
        
            status = subData.status ?? '-'
            

            let add = false
            if (
                (state.showStatus == 0) || // all
                ((state.showStatus == 1) && (status == 'trialing')) ||
                ((state.showStatus == 2) && (status == 'active')) ||
                ((state.showStatus == 3) && (status == '-')) ||
                ((state.showStatus == 4) && (line.launch)) ||
                ((state.showStatus == 5) && (!line.launch.length == 0)) 
               )
            {
                add = true 
            }

            if (add && filterCountry!="All")
            {
                if (line.country!=filterCountry) add = false 
            }




            // apply launch time filter
            if (pageKind == 'report'){
                let launchFilterFrom = ''+state.timeFilter.list[state.timeFilter.fromIndex].year+state.timeFilter.list[state.timeFilter.fromIndex].month
                let launchFilterTo = ''+state.timeFilter.list[state.timeFilter.toIndex].year+state.timeFilter.list[state.timeFilter.toIndex].month
                let launchYearMonth = line.launch ? line.launch.replace('-','').substring(0,6) : '-'
                
                if (launchYearMonth < launchFilterFrom || launchYearMonth > launchFilterTo) {
                    add = false 
                }
            }

            // apply launched - not launched

                 
            
            

            if (add){
                let lineObj = []
                lineObj.push(line.id)
                lineObj.push(subscriptionEmbargoType)
                lineObj.push(line.name)
                if (pageKind == 'report') lineObj.push(line.launch)
                lineObj.push(line.country)
                
                lineObj.push(subscriptionName)
                lineObj.push(status)

                lineObj.push(payment)
                
                

                arrayTable.push(lineObj)
                
            }
            
            countries[line.country] = 1
           
        });

        if (state.sortBy == 2){ //type
            arrayTable.sort((a, b) => {
                var ta = a[1] 
                var tb = b[1] 

                let ta_i = (ta == 'F') ? 2:((ta == 'β') ? 1:0)
                let tb_i = (tb == 'F') ? 2:((tb == 'β') ? 1:0)
                

                return (ta_i < tb_i) ? 1 : (ta_i > tb_i) ? -1 : 0;
            });
        }
        else if (state.sortBy >= 1){ 
            let index = 2
            switch (state.sortBy) {
                case 1: index = 2 ; break; // name
                case 3: index = 4 ; break; // Subscription Name
                case 4: index = 5 ; break; // Subscription Type
                case 5: index = 6 ; break; // Subscription Payment
            
                default:
                    break;
            }
            arrayTable.sort((a, b) => {
                var ta = a[index].toUpperCase();
                var tb = b[index].toUpperCase();
                return (ta < tb) ? 1 : (ta > tb) ? -1 : 0;
            });
        }
        

        if (state.showCountry.list.length <= 1){
            state.showCountry = {list:['All', ... Object.keys(countries)] , status:'All', index:0}
        }

        state.totalVenues = arrayTable.length
        state.table_lines = arrayTable    
    } 

    
    const getPriceIds = (country) => {

        if (process.env.VUE_APP_ENV == 'dev'){
            if (country == 'Poland'){
                const poland_pricing = [
                    ['Basic Monthly Poland','price_1JEybOGm7D0DRMmqUxRwOoTC'],
                    ['Plus Monthly Poland','price_1JEycuGm7D0DRMmq2JMkC7lI'],
                    ['Premium Monthly Poland','price_1JFbeLGm7D0DRMmq2hLMxvuS'],
                    ['Basic Annually Poland','price_1JEybOGm7D0DRMmqUxRwOoTC'],
                    ['Plus Annually Poland','price_1JEycuGm7D0DRMmq2JMkC7lI'],
                    ['Premium Annually Poland','price_1JFbeLGm7D0DRMmq2hLMxvuS']
                 ]
                return poland_pricing
            }
            else {
                const uk_pricing = [
                    ['Basic Monthly','price_1JEybOGm7D0DRMmqUxRwOoTC'],
                    ['Plus Monthly','price_1JEycuGm7D0DRMmq2JMkC7lI'],
                    ['Premium Monthly','price_1JFbeLGm7D0DRMmq2hLMxvuS'],
                    ['Basic Annually','price_1JEybOGm7D0DRMmqUxRwOoTC'],
                    ['Plus Annually','price_1JEycuGm7D0DRMmq2JMkC7lI'],
                    ['Premium Annually','price_1JFbeLGm7D0DRMmq2hLMxvuS']
                ]
                return uk_pricing
            }
        }
        else {
            if (country == 'Poland'){
                const poland_pricing = [
                    ['Basic Monthly Poland','price_1J4rjgGm7D0DRMmq9Wid92Fa'],
                    ['Plus Monthly Poland','price_1J4rr7Gm7D0DRMmquWNEvhPJ'],
                    ['Premium Monthly Poland','price_1J4rsSGm7D0DRMmqhycquHuM'],
                    ['Basic Annually Poland','price_1J4rjgGm7D0DRMmq9Wid92Fa'],
                    ['Plus Annually Poland','price_1J4rr7Gm7D0DRMmqUKBRP80Z'],
                    ['Premium Annually Poland','price_1J4rsSGm7D0DRMmqVvjGKAav']
                ]
                return poland_pricing
            }
            else {
                const uk_pricing = [
                    ['Basic Monthly','price_1J4rjgGm7D0DRMmqONMpR7uO'],
                    ['Plus Monthly','price_1J4rr7Gm7D0DRMmqnag1uj8L'],
                    ['Premium Monthly','price_1J4rsSGm7D0DRMmqvSCklXR5'],
                    ['Old Basic','price_1JIAtFGm7D0DRMmqQAFBKUsL'],
                    ['Markets','price_1JIAuHGm7D0DRMmqrZQnJv2G'],
                    ['Basic Annually','price_1J4rjgGm7D0DRMmqONMpR7uO'],
                    ['Plus Annually','price_1J4rr7Gm7D0DRMmqoODfzYd8'],
                    ['Premium Annually','price_1J4rsSGm7D0DRMmqbXIxYYT7']
                ]
                return uk_pricing
            }
        }
    }


    // actions
    const filterChanged = (kind,value) => {
        
        if (kind == 'show'){
            state.showStatus = value.index
        }
        else if (kind == 'sort'){
            state.sortBy = value.index
        }
        else if (kind == 'country'){
            state.showCountry.status = value.value
        }
        else if (kind == 'from-filter'){
            state.timeFilter.fromIndex = value.index
        }
        else if (kind == 'to-filter'){
            state.timeFilter.toIndex = value.index
        }
        

        
        analyseTableData()
    }


    // ***** netword
    const getData = async () => {
        state.error_value = ''


        // get stripe data from Stripe Server
        let reqResSubs = await store.dispatch('requests/sendApiRequest', {
            endpoint:'subscriptions/list-stripe-subscriptions',
            method:'post'
        }); 
        stripeSubscription = reqResSubs.subs ?? {}
       
        let reqRes = await store.dispatch('requests/sendApiRequest', {
            endpoint:'subscriptions/list',
            method:'post',
            where:[ ['released',true]]
        }); 
    
        
        parseSubscriptions(reqRes.subscriptions || [])

        state.arrayVenues = reqRes.list || []
        analyseTableData()

        state.pageLoading = false
        showSubscriptionDetail(activeVenueID)
      
    }


    const addSubscription = async () => {
        
        let kind = state.subShowPanel

        let trialEndTimestamp = Math.round(state.addSubscriptionPanel.trialEndDate.getTime() / 1000)
        let email = state.addSubscriptionPanel.email
        let priceID = state.addSubscriptionPanel.priceID
        let cusID = state.addSubscriptionPanel.cusID
        
  
        
        
        let venueID = state.addSubscriptionPanel.venueID
        let plan_name = state.addSubscriptionPanel.plan_name

        if (kind == 'add-subscription' && email.length < 4) state.addSubscriptionPanel.error = 'email is required.'
        else if (!priceID) state.addSubscriptionPanel.error = 'subscription is required.'
        else if (kind == 'include-in-subscription' && cusID.length == 0) state.addSubscriptionPanel.error = 'stripe customer id is required.'
        else {
            let sendingObj = {
                    endpoint:'subscriptions/register-subscription',
                    method:'post',
                    api_key:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwbGF0Zm9ybSI6ImZyb250LW1hbmFnZW1lbnQiLCJpYXQiOjE1MTYyMzkwMjJ9.987FOSXOmzdSLN0ss-RqN-wYH9J27UhK9tSeiQCjFo4',
                    venue_id:venueID,
                    subscription_email:email,
                    price_id:priceID,
                    trial_end_timestamp:trialEndTimestamp,
                    plan_name:plan_name
            }

            if (cusID.length > 0) sendingObj.cus_id = cusID
        
            

            state.savingStatus = 'adding subscription, please wait ...'
            await store.dispatch('requests/sendApiRequest', sendingObj); 

            //EmabrgoApi_Management_Version_

            getData()
        }

    }

    const editSubscription = async () => {

        
        let trialEndTimestamp = Math.round(state.addSubscriptionPanel.trialEndDate.getTime() / 1000)
        let priceID = state.addSubscriptionPanel.priceID
        let venueID = state.addSubscriptionPanel.venueID
        let plan_name = state.addSubscriptionPanel.plan_name



        if (!priceID) state.addSubscriptionPanel.error = 'subscription is required.'
        else {
            state.savingStatus = 'updating subscription, please wait ...'
            await store.dispatch('requests/sendApiRequest', {
                endpoint:'subscriptions/register-subscription',
                method:'post',
                api_key:'aaa',
                price_id:priceID,
                trial_end_timestamp:trialEndTimestamp,
                venue_id:venueID,
                plan_name:plan_name
            }); 
    
            getData()
        }

    }

    const cancelSubscription = async () => {

        let venueID = state.addSubscriptionPanel.venueID
        
        state.savingStatus = 'removing subscription, please wait ...'
        await store.dispatch('requests/sendApiRequest', {
            endpoint:'subscriptions/cancel-subscriptions',
            method:'post',
            api_key:'aaa',
            venue_id:venueID
        }); 
        
        getData()
    }

    const getStripeJsonData = async (options={}) => {
        console.log({options});
        
        let reqObj = {method:'post'}
        reqObj.endpoint = 'subscriptions/stripe-json-data'

        console.log({reqObj});

        if (options.subID){
            let subID = options.subID ?? ''
            state.json = `requesting subscription data (${subID})....`
            reqObj.sub_id = subID
        }
        else if (options.cusID){
            let cusID = options.cusID ?? ''
            state.json = `requesting customer data (${cusID})....`
            reqObj.cus_id = cusID
        }
        else if (options.paymentID){
            let paymentID = options.paymentID ?? ''
            state.json = `requesting payment data (${paymentID})....`
            reqObj.payment_id = 'pm_1IlckJGm7D0DRMmqnJJeuFx2'
        }

        let reqResSubs = await store.dispatch('requests/sendApiRequest', reqObj); 
      
        if (reqResSubs.error_message)
            state.json += reqResSubs.error_message
        else if (reqResSubs?.data?.stripe_data)
            state.json = reqResSubs.data.stripe_data ?? ''
        else 
            state.json += 'error'      
    }
    

    return { lineClicked , refreshSubscriptions , getData , getPriceIds , addSubscription , editSubscription , 
        chanegState , filterChanged, csvClicked, panelButtonPressed ,
        panelMainButtonClicked , stripeSubscription , cancelSubscription,
        getStripeJsonData}
    
}
