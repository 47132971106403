<template>
<section style=''>
  
  <page-header>{{pageTitle}}</page-header>
  <!-- <panel>
          Search
  </panel> -->

  
  <table-s1 v-if="state.table_lines" class='mt-20' title="Order list" 
    :header_titles=state.table_header_titles :lines=state.table_lines :header_widths=[0,0,0,400,0] />
  <container>loading ... </container>

</section>
</template>

<script> 
import { reactive } from 'vue'
import { useStore } from 'vuex'


export default {
  setup(){
    const store = useStore()
    const state = reactive({
      table_header_titles:[],
      table_lines:null
    })
    const pageTitle = 'Promocode List'

    state.table_header_titles = ['#','code','description','venue','unique']
    

    const getList = async () => {
      
        let params = {
        }


        let reqRes = await store.dispatch('requests/sendApiRequest', {
            endpoint:'promocodes/list',
            method:'post',
            params:params     
        }); 

        analyseTableData(reqRes.list ?? [])
        
    }

    const analyseTableData = (result_data) => {
      
      state.table_lines = [];
      let uniqueObj = {'0':'No', '1':'Yes', '2':'Yes (Claimed)'};

      result_data.forEach(line => {
        state.table_lines.push([
          line.id,
          line.code,
          line.description, 
          line.exceptions, 
          uniqueObj[line.unique]
        ])
      })


    }

    getList()

    return {state , pageTitle}
  }
}
  
    
</script>

<style scoped>

</style>