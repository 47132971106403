<template>
    <section>
         <div class='title-container'>
            <p><slot> </slot> </p>
         </div>    
        
    </section>
</template> 

<style scoped>
.title-container{width:100%; height:60px;}
.title-container p {margin-left:20px; padding-top:20px; font-size: 24px;}
</style>