

import { useStore } from 'vuex'
// import functions from '../../functions'
import { useRouter } from 'vue-router'

export default function useEntityHook(state) {
    const store = useStore()
    const router = useRouter()
    const requestUser = async (userID) => {
        
        let reqRes = await store.dispatch('requests/sendApiRequest', {
            endpoint:'portal-user/list',
            method:'post',
            entity_id:userID
            
        }); 

        if ((reqRes.list ?? []).length > 0){
            state.name = reqRes.list[0].name
            state.email = reqRes.list[0].email
            state.venues = reqRes.list[0].venue_ids
        }
        
    }
   
    const requestUserList = async () => {
        
        let reqRes = await store.dispatch('requests/sendApiRequest', {
            endpoint:'portal-user/list',
            method:'post',
            params:{
              name:state.name,
              email:state.email,
              venue_ids:state.venues
            }
        }); 
        
       
        let result_data = reqRes.list ?? [];
 
        let array_lines = [];
        result_data.forEach(line => {
            let access = ''
          switch(line.access) {
              case 1: access = 'Admin';break;
              case 2: access = 'Marketing';break;
              case 3: access = 'Sales';break;
          }
          array_lines.push([line.id ?? '',
                            line.name ?? '',
                            line.email ?? '',
                            line.venue_ids ?? '',
                            access,
                            [{type:'icon-button', link:'/portal-user/edit/'+line.id, icon:'edit'}]
          ]);
          
        });
        state.lines = array_lines;
    }

    const requestAddPressed = async () => {
        state.error_value = ''
         
        
        if (state.name.length < 4)  state.error_value = 'Full name is required'
        else if (state.email.length < 4)  state.error_value = 'email is required'
        else if (state.venues.length < 2)  state.error_value = 'venue ids are required'
        else {
          let reqRes = await store.dispatch('requests/sendApiRequest', {
              endpoint:'portal-user/add-user',
              method:'post',
              params:{
                name:state.name,
                email:state.email,
                venue_ids:state.venues,
                entity_id:state.entityID,
                password:state.password
              }
          }); 

          if (reqRes?.result == 'success'){
            router.push('/portal-user/list')
          }
          console.log({reqRes});
        }
        
      }
    

    return { requestUserList , requestAddPressed , requestUser}
    
}
