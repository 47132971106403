import axios from 'axios';

export default {

  async sendRequest(_,params) {
  
      // let request_result = false;
      let url = process.env.VUE_APP_URL+params.endpoint;   
      let api_params = params.params

      console.log('url',url);
      

      const data = new FormData() 
      
      if (url && api_params)
      {
        data.append('params', JSON.stringify(api_params))
      }
      else 
      {
        console.log("params doesn't have required fields (url,api_params)");
      }
      
      let received_data = {};
      await axios({ 
        method: 'post',
        url: url,
        headers: { 'content-type': 'application/json', 'Accept': 'application/json' },
        data: data
      })
      .then((response) => {
        console.log('response',response.data);
        
        let api_result = response.data.result;
        if (api_result == 'success')
        {
          received_data = response.data.value;
        }
        

        
      });

      return received_data;
  } ,
  async sendApiRequest(_,params) {
  
    
      let url = process.env.VUE_APP_PORTAL_API_URL+params.endpoint 
      // console.log(url);
      
      let method = params.method || 'post'  
      delete params.endpoint
      delete params.method



      // console.log('params',params);

      let received_data = {};
      await axios({ 
        method: method,
        url: url,
        source: 'management',
        api_key:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwbGF0Zm9ybSI6ImZyb250LW1hbmFnZW1lbnQiLCJpYXQiOjE1MTYyMzkwMjJ9.987FOSXOmzdSLN0ss-RqN-wYH9J27UhK9tSeiQCjFo4',
        headers: {'Content-Type': 'application/json'}, 
        data: params
      })
      .then((response) => {
        received_data = response.data;
        
      })
      .catch((error) => {
        console.log('**** e', {error});
        
      })
      // console.log('received_data',received_data);
      
      return received_data;
  } 
};


