<template>
<section style=''>
  
  <page-header>New Monthly Venues </page-header>

  

  <div v-if="state.pageStage == 1">
    <panel>
          <div class='first-panel-container'>
            <div style='text-align:center'>
              Generating the report  <br>
              please wait ....
            </div>
              <img  class='mt-30' width=50 src="@/assets/loading_dots.gif"/>
          </div>
      </panel>
  </div>


  <div v-if="state.pageStage == 2">
      <!-- <panel>
          <div class='flex gap-20'>
            
            <div class='flex-11'>
              <select-box class='mt-10' title="Venues' Category" :items=vanue_categories @changed="venueCategoryChanged"></select-box>
            </div>

            <div class='flex-11'>
              <select-box class='mt-10' title="Starting From" :items=starting_from @changed="StartFromChanged"></select-box>
            </div>

            <div class='flex-11'>
              <select-box class='mt-10' title="Report Duration" :items=duration_select @changed="durationChanged"></select-box>
            </div>
          </div>
      </panel> -->

      <panel style='max-width:1200px'>
        <div class='flex-11auto'>
          <!-- <div class='fs-18'>User Acquisition Growth Rate</div> -->
          <line-chart :chartdata="state.chartDataGrowth" />
        </div>
      </panel> 

      <div>
        <container><div class='field-title'>Monthly Venues</div></container>
        <table-s1 title="Venues Growth" :header_titles=state.tableHeader :lines=state.tableLines />
      </div>

      <div class='mt-40'>
        <container><div class='field-title'>All Venues</div></container>
        <table-s1 title="Venues Growth" :header_titles=state.tableAllVenues.header :lines=state.tableAllVenues.lines @valueChanged="tableValueChanged"></table-s1>
      </div> 
  </div> 

  

  

</section>
</template>

<script>
import { reactive } from 'vue'
import {useStore} from 'vuex'
import functions from '@/functions.js'
import LineChart from '@/components/elements/LineChart2.vue'


export default {
  
  components: { LineChart },
  setup(){
    const store = useStore()
    const state = reactive({
      chartDataGrowth: {
              title:'', 
              colors:["#FD4648", "#F7F7F8"], 
              labels:['1', '2', '3', '4', '5', '6'], 
              values:[12,2,3,40,5,6], 
              valueType: 'number' ,
              chartName: 'qqwqwqw', 
              legend_postition:'bottom'},
      pageStage:1,
      tableLines:[],
      tableHeader:['Month','Not Active - Not Released', 'Not Active - Released', 
                'Active - Not Released', 'Active - Released','All'],
      tableAllVenues:{header:['#','Name','Active','Released','Launched','Registered'] ,lines:[]}
    })


    const getData = async () => {
       
        let reqRes = await store.dispatch('requests/sendApiRequest', {
            endpoint:'reports/venues/monthly-growth',
            method:'post',
            request_type:'all'
            
        }); 
        const list = reqRes.data ?? []
        state.pageStage = 2
        let venueMonth = []
        
        const venuesInMonth = {}
        venuesInMonth['-'] = {na_nr:0, na_r:0, a_nr:0, a_r:0, t:0 }
        
        list.forEach(venue => {

          // for all venues table 
          const registered = venue.rtimestamp.substring(0,16).replace('T',' ')// 2021-08-07T13:41:46.002Z
          state.tableAllVenues.lines.push([
            venue.id, venue.name , venue.active , venue.released , venue.launch??'' , registered
          ])





          // for monthly table
          let launch = venue.launch ?? ''
          launch = launch.split('-')
          
          let key = '-'
          if (launch.length > 2){
            key = launch[1]+'/'+launch[0]
          }
          else {
            const rDate = venue.rtimestamp.substring(0,10)
            if (rDate != '2021-08-17'){
              const dateComp = rDate.split('-')
              key = dateComp[1]+'/'+dateComp[0]
            }
          }
        
          

          if (!venuesInMonth[key]) venuesInMonth[key] = {na_nr:0, na_r:0, a_nr:0, a_r:0,t:0 }

          if (venue.released && venue.active)
            venuesInMonth[key].a_r = venuesInMonth[key].a_r+1
          else if (!venue.released && venue.active)
            venuesInMonth[key].a_nr = venuesInMonth[key].a_nr+1
          else if (!venue.released && !venue.active)
            venuesInMonth[key].na_nr = venuesInMonth[key].na_nr+1
          else if (venue.released && !venue.active)
            venuesInMonth[key].na_r = venuesInMonth[key].na_r+1
          
          venuesInMonth[key].t = venuesInMonth[key].t+1
        })
       
        let chartLabels = []
        let chartvalues = []
        state.tableLines = []
        // not launched and have no date
        const thisVenuesInMonth = venuesInMonth['-']
        state.tableLines.push(['Not Available', thisVenuesInMonth.na_nr, thisVenuesInMonth.na_r, 
              thisVenuesInMonth.a_nr, thisVenuesInMonth.a_r,thisVenuesInMonth.t])

        {
          let index_d = 201704;
          let now = new Date();
          let datetime = now.toISOString().split('T')[0].split('-');
          let end = parseInt(datetime[0]+''+datetime[1]);
      
          while (index_d <= end)
          {
            let start_str_ar = functions.splitAtIndex(String(index_d),4);
            venueMonth.push(start_str_ar[1]+'/'+start_str_ar[0]);

            index_d = functions.getNextYearMonth({year_month_int:index_d}).int;


            const key = start_str_ar[1]+'/'+start_str_ar[0]
            const thisVenuesInMonth = venuesInMonth[key] ?? {na_nr:0, na_r:0, a_nr:0, a_r:0,t:0 }
            chartLabels.push(key)
            chartvalues.push(thisVenuesInMonth.t)
            
            state.tableLines.push([key, thisVenuesInMonth.na_nr, thisVenuesInMonth.na_r, 
                thisVenuesInMonth.a_nr, thisVenuesInMonth.a_r,thisVenuesInMonth.t])
          }

          
        }
        state.chartDataGrowth.labels = chartLabels  
        state.chartDataGrowth.values = chartvalues 

        state.tableLines.reverse()
    }

    getData()

    return { state }
  }
  
  // mounted(){
  //   // localStorage.removeItem('venues_list');
  //   // localStorage.removeItem('report_growth_venues_data');
    
  //   // fill starting_from
  //   {
  //     let index_d = 201704;
  //     let now = new Date();
  //     let datetime = now.toISOString().split('T')[0].split('-');
  //     let end = parseInt(datetime[0]+''+datetime[1]);
   
  //     while (index_d <= end)
  //     {
  //       let start_str_ar = functions.splitAtIndex(String(index_d),4);
  //       this.starting_from.push(start_str_ar[1]+'/'+start_str_ar[0]);
  //       index_d = functions.getNextYearMonth({year_month_int:index_d}).int;
  //     }
  //   }



  //   if (functions.checkData('venues_list') && functions.checkData('report_growth_venues_data'))
  //   {
  //     this.page_stage = 3;
  //     this.prepareData(functions.getData('venues_list'),functions.getData('report_growth_venues_data'))
  //   }
  // },
  // components: {
  //   LineChart
  // },
  // data(){
  //   return{
  //     months:[],
  //     starting_from:[],
  //     active_start:201704,
  //     duration_select:Array.from(Array(22), (_,index)=>{ return (index+3)+" Months"}),
  //     header_titles_main : ['Venue Name','Venue ID','Start'],
  //     header_titles_summary : ['Description'],
  //     master_data:[],
  //     lines:[],
  //     page_stage:1,
  //     summary_lines:[],
  //     chart_data_growth:{},
  //     page_data:{},
  //     report_period:12,
  //     show_chart:false,
  //     vanue_categories:["All","Coffee Shop","Bar","Grab & Go","Pub","Club","Desert Parlour","Food stand","Restaurant","Nightclub"],
  //     active_category:'All',
  //   }
  // },
  // methods:{
  //   tableValueChanged(event){
  //     let value = event.value;
  //     let a = event.id.split('_');
      
  //     if (a[0] === 'checkbox') {
  //       if (a[1] === 'header'){
  //         this.header_titles_main[0].value = value;
          
  //         this.lines.forEach(line => {
  //           line[0].value = value;
  //         });
          
  //       }
  //       else this.lines[a[1]][0].value = value;
  //     }
      
  //     this.createSummary();
  //   },
  //   StartFromChanged(value){
  //     let a = value.split('/');
  //     this.active_start = parseInt(a[1]+''+a[0]);
  //     this.processData();
  //   },
  //   venueCategoryChanged(value){
  //     this.active_category = value;
  //     this.processData();
  //   },
  //   durationChanged(value){
  //     let duration = parseInt(value.split(' ')[0]);
  //     this.report_period = duration;

  //     this.processData();
  //   },
    
  //   generateReportPressed(){
  //     this.page_stage = 2;
  //     this.requestData();
  //   },
  //   async requestData(){
      
  //      let report_data = [], venue_list = [];
  //     //  if ((!!functions.checkData('venues_list')) || (!!functions.checkData('report_growth_venues_data')))
  //      {
  //         let result = await this.$store.dispatch('requests/sendRequest', {
  //           endpoint:'venue-monthly-user-growth',
  //           params:{ }             
  //         });
  //         report_data = result.list ?? [];
  //         venue_list = result.venues ?? [];

  //         functions.saveData(venue_list,'venues_list');
  //         functions.saveData(report_data,'report_growth_venues_data');
  //      }
  //     //  else 
  //     //  {
  //     //     venue_list = functions.getData('venues_list'); 
  //     //     report_data = functions.getData('report_growth_venues_data');
          
  //     //  }

  //      this.prepareData(venue_list,report_data)
       
  //   },
  //   prepareData(venue_list,report_data){

  //       let dict_venues = {}
  //       venue_list.forEach(line => {
  //         dict_venues[String(line['id'])] = line;
  //       });


  //       report_data.forEach(line => {
  //         let venue_id = line.id ?? 0;
  //         let year = line.year ?? 0;
  //         let month = line.month ?? 0;
  //         let user_count = line.count ?? 0;
  //         let venue_name = dict_venues[venue_id].name ?? '-'; 
  //         let venue_categories = dict_venues[venue_id].categories ?? '-';

  //         let key = functions.getYearMonth({year:year, month:month}).int;

  //         if (!this.page_data[venue_id]) 
  //         {
  //            let start_str_ar = functions.splitAtIndex(String(key),4);
  //            let start_str = start_str_ar[1]+'/'+start_str_ar[0];
  //            this.page_data[venue_id] = {start_str:start_str, start:key, end:key, venue_name:venue_name, venue_categories:venue_categories, items:{}};
  //         }
  //         else this.page_data[venue_id]['end'] = key;
          
  //         this.page_data[venue_id]['items'][key] = user_count;
  //       });
        
  //       this.processData();
  //   },
  //   processData(){
  //       let processed_data = [];
  //       this.lines = [];
  //       let number_of_months = this.report_period;
  //       this.months = [];
        
  //       for(let i=1;i<=this.report_period;i++)
  //       {
  //         let key = 'M'+i;
  //         this.months.push(key)
  //       }
  //       this.header_titles_main = [{type:'checkbox', value:true},'Venue Name','Venue ID','Start', ...this.months],
  //       this.header_titles_summary = ['Description',...this.months],


  //       Object.keys(this.page_data).forEach( venue_id  => {
    
  //         let line = this.page_data[venue_id];
  //         let adding_ok = true;
          

  //         if (this.active_category != 'All')
  //         {
  //             if (!(line.venue_categories.includes(this.active_category)))
  //               adding_ok = false;
  //         }
          
  //         if (line.start < this.active_start) adding_ok = false;

  //         if (adding_ok)
  //         {
  //           let start = line.start;
  //           let end = line.end;

  //           let line_data = [{type:'checkbox', value:true},line.venue_name,venue_id,line.start_str];
          
            
        
  //           let index_yearmonth = start;
  //           let number_of_user = 0;
  //           for (let i=0; i<number_of_months; i++)
  //           {
  //               let new_user = line.items[index_yearmonth] ?? 0;
  //               number_of_user += parseInt(new_user);
  //               if (index_yearmonth<end) 
  //                 line_data.push(String(number_of_user));
  //               else 
  //                 line_data.push('')
              
              
  //               index_yearmonth = functions.getNextYearMonth({year_month_int:index_yearmonth}).int;
  //           }
  //           processed_data.push(line_data)
  //         }
          
  //       });
          
  //       this.lines = processed_data;
  //       this.page_stage = 3;
  //       this.createSummary();
  //   },
  //   createSummary(){

  //       // summary 
  //       let array_sum_totals = new Array(this.report_period).fill(0);
  //       let array_sum_number_venues = new Array(this.report_period).fill(0);
  //       let array_sum_average_venues = new Array(this.report_period).fill(0);
  //       let array_sum_growth_total = new Array(this.report_period).fill(0);
  //       let array_sum_growth_average = new Array(this.report_period).fill(0);

        
  //       this.lines.forEach( line => {
  //           if (line[0].value){
  //             for (let i=4; i<(this.report_period+4);i++){
  //               if (line[i] != '')
  //               {
  //                 array_sum_totals[i-4] += parseInt(line[i]);
  //                 array_sum_number_venues[i-4] ++;
  //               }
  //             }
  //           }
  //       });


  //       // calculate average & growth
  //       for (let i=0; i<this.report_period;i++){
  //         if (parseInt(array_sum_number_venues[i]) > 0)
  //         {
  //           let average =  (parseFloat(array_sum_totals[i]) / parseFloat(array_sum_number_venues[i])).toFixed(2);
  //           array_sum_average_venues[i] = +(Math.round(average + "e+2")  + "e-2")

  //           if (i>0){
  //             array_sum_growth_total[i] =  (((parseFloat(array_sum_totals[i]) - parseFloat(array_sum_totals[i-1])) / parseFloat(array_sum_totals[i-1])) * 100).toFixed(2);
  //             array_sum_growth_average[i] = (  ((parseFloat(array_sum_average_venues[i]) - parseFloat(array_sum_average_venues[i-1])) / parseFloat(array_sum_average_venues[i-1]))*100  ).toFixed(2);
  //           }
  //         }
  //       }
  //       this.summary_lines = [['Total User Acquisition',...array_sum_totals],
  //                             ['Number of Venues',...array_sum_number_venues],
  //                             ['Average',...array_sum_average_venues],
  //                             ['Growth (total)',...array_sum_growth_total.map(x => (x>0) ? x +"%" : x) ],
  //                             ['Growth (average)',...array_sum_growth_average.map(x => (x>0) ? x +"%" : x)]
  //                             ];
        
  //       setTimeout( () => {
  //          this.chart_data_growth = {'labels':this.months, 'values':[array_sum_growth_total,array_sum_growth_average]};
  //       }, 200);
        
        

      
  //   },
    
  // }
}
</script>

<style scoped>
.first-panel-container {display: flex; flex-direction: column; align-items: center;
    justify-content: center;}
</style>