<template>
    <main>
        <div class='flex flex-items-center'>
            
            <div @click="radioClicked" :class=" (type=='radio' ? 'radio-box':'check-box') + (state.selected ? ' selected':'') ">  </div>
            
            <div class='fs-15 semi-bold ml-5'> {{title}}</div>
            <!-- <div class='ml-10' v-if=" showEditBox ">
                <editbox :readonly=" !state.selected  " type="number" class='editbox' @changed="editChanged"> </editbox>
            </div>  -->
        </div> 
    </main>
</template>


<script>
import { reactive  } from 'vue'
// import { useStore } from 'vuex'

// emit values: selected (bool) , value (string)
export default {       

    props: {
        title:{ type: String, default: ''},
        value:{ type: Boolean, default: false},
        type:{ type: String, default: 'radio'},
        showEditBox:{ type: Boolean, default: false},
         
    },
    // emits:['statusChanged'],
    setup(props,context){
        // const store = useStore()

        const state = reactive({
            selected:props.value,
            editValue:''
        })


        const radioClicked = () => {
            if (props.type == 'radio' && !state.selected) 
            {
                state.selected = true
                emitValues()
            }
            else if (props.type == 'checkbox'){
                state.selected =  !state.selected
            }
        }
        // const editChanged = (value) => {
        //     state.editValue = value
        //     // console.log('v',value);
        //     emitValues()
            
        // }

        const emitValues = () => {
            context.emit('statusChanged', state.selected )
        }

        // watch(() => props.value, (newValue) => {
        //     state.selected = newValue
        // });


        return { state , radioClicked}
        // , title ,  , editChanged}
    }
    
}
</script>

<style scoped  lang="scss">

.editbox {width:60px;}
.radio-box { width:18px; height: 18px; min-width:18px;  background: gray; border:1px solid red; border-radius: 50%; 
    &.selected {border:5px solid green;}
}
// .check-box { width:18px; height: 18px; min-width:18px;  background: $color-textbox-background; border:1px solid $color-textbox-border; border-radius: 5px; 
//     &.selected {background:  $color-green;}
// }

</style>