<template>
<section style=''>
  
  <page-header>Venue's Users</page-header>
  <panel>
      <div class='flex gap-20'>
        <div class='flex-11'>
          <editbox class='mt-10' title='Venue IDs' @enterPressed="enterPressed"></editbox>
          <p class='field-explain'> Please enter one venue id or seperate them by with comma like 501,502 for multiple venues. </p>
        </div>
        <div class='flex-11'>
          <select-box class='mt-10' title='Venue Category' :items=vanue_categories @changed="categoryChanged"></select-box>
        </div>
      </div>
  </panel>

  <panel style='max-width:1200px' v-show="show_chart">
    <div class='flex-11auto'>
      <div class='fs-18'>Venue User Growth</div>
      <line-chart :chart_data=chart_data ></line-chart>
    </div>
  </panel>

  <table-s1 title="Users list" :header_titles=header_titles :lines=lines></table-s1>

</section>
</template>

<script>
import functions from '@/functions.js'
import LineChart from '@/components/elements/LineChart.vue'

export default {
  

  components: {
    LineChart
  },
  data(){
    return{
      header_titles : ['ID','Name','Email','Registered'],
      lines:[],
      chart_data:{},
      show_chart:false,
      vanue_categories:["Coffee Shop","Bar","Grab & Go","Pub","Club","Desert Parlour","Food stand","Restaurant","Nightclub"]
    }
  },
  methods:{
    async enterPressed(value){
      if (value.length > 0)
      {
        let result = await this.$store.dispatch('requests/sendRequest', {
          endpoint:'venue-user-growth',
          params:{ venue_ids:value}             
        });
        
        let result_data = result.list ?? [];
        this.analyseReceivedData(result_data);
      }
      
    },
    async categoryChanged(value){
      console.log(value);

      let result = await this.$store.dispatch('requests/sendRequest', {
          endpoint:'venue-category-user-growth',
          params:{ venue_category:value}             
        });
        
        let result_data = result.list ?? [];
        console.log(result_data);

        let array_chart_raw = [];
        result_data.forEach(element => {
              let year = element.year ? Math.round(element.year):0;
              let month = element.month ? Math.round(element.month):0;
              let user_count = element.month ? Math.round(element.user_count):0;


              let key = functions.getYearMonth({year:year, month:month}).int;
              array_chart_raw[key] = user_count;
              

        });

        let current_year = new Date().getFullYear();
        let current_month = new Date().getMonth();
        
        let first_year = 2018;
        let first_month = 1;

        let current_year_month = functions.getYearMonth({year:current_year, month:current_month}).int;
        let first_year_month = functions.getYearMonth({year:first_year, month:first_month}).int;
        
        
        let chart_data_labels=[];
        let chart_data_values=[];
        if (first_year_month <= current_year_month)
        {
          let user_count = 0;
          let index_year_month = first_year_month;
          do{
            if (array_chart_raw[index_year_month]) user_count += array_chart_raw[index_year_month];

            chart_data_labels.push(index_year_month);
            chart_data_values.push(user_count);


            index_year_month = functions.getNextYearMonth({year_month_int:index_year_month}).int;
          } while (index_year_month <= current_year_month)

        }
        this.show_chart = true;
        this.chart_data = {'labels':chart_data_labels, 'values':[chart_data_values]};
        
        console.log(this.chart_data);
        
        
      
    },
    analyseReceivedData(result_data){
      let array_lines = [];

        if (result_data.length > 0)
        {
            let array_chart_raw = [];
            result_data.forEach(element => {
              let id = element.id ?? '';
              let email = element.email ?? '';
              let name = element.name ?? '';
              let year = element.year ? Math.round(element.year):0;
              let month = element.month ? Math.round(element.month):0;

              array_lines.push([id,name,email,functions.getMonthStr(month)+' '+year]);

              let key = functions.getYearMonth({year:year, month:month}).int;
              if (array_chart_raw[key]) array_chart_raw[key]++;
              else array_chart_raw[key] = 1;

            });


            let current_year = new Date().getFullYear();
            let current_month = new Date().getMonth();

            let first_year = Math.round(result_data[0].year);
            let first_month = result_data[0].month;

            let current_year_month = functions.getYearMonth({year:current_year, month:current_month}).int;
            let first_year_month = functions.getYearMonth({year:first_year, month:first_month}).int;
            
            
            
            let chart_data_labels=[];
            let chart_data_values=[];
            if (first_year_month <= current_year_month)
            {
              let user_count = 0;
              let index_year_month = first_year_month;
              do{
                if (array_chart_raw[index_year_month]) user_count += array_chart_raw[index_year_month];

                chart_data_labels.push(index_year_month);
                chart_data_values.push(user_count);


                index_year_month = functions.getNextYearMonth({year_month_int:index_year_month}).int;
              } while (index_year_month <= current_year_month)

            }
            this.show_chart = true;
            this.chart_data = {'labels':chart_data_labels, 'values':[chart_data_values]};
            
            console.log(this.chart_data);
            
            
            // console.log('current_year',current_year);
            // console.log('current_month',current_month);
            

        }
        this.lines = array_lines;   
    },
    
  }
}
</script>

<style scoped>
</style>



