

var functions = {
    getMonthArray() {
        return [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ]
    },
    getMonthStr (month) { 
        // let months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ]
        let months = this.getMonthArray()
        return months[month-1]
    },
    
    timestampToDate (timestamp) {
        const date = new Date(timestamp*1000);
        console.log(timestamp,{date});
        
        const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = date.getFullYear();
        var month = months[date.getMonth()];
        var day = date.getDate();

        return ({descriptiveDate: day + ' ' + month + ' ' + year})

    },
    getYearMonth (options=[])
    {
        let year = options['year'] ? Math.round(options['year']) : 0;
        let month = options['month'] ? Math.round(options['month']) : 0;
        
        let return_value = year+''+(month>=10 ? month:'0'+month);
        return {str:return_value, int:parseInt(return_value)};
    },
    getNextYearMonth (options=[])
    {
        let year_month_int = options['year_month_int'] ? options['year_month_int'] : 0;
        if (year_month_int>0)
        {
            let year = year_month_int.toString().substring(0, 4);
            let month = parseInt(year_month_int.toString().substring(4))+1;

            if (month > 12) {month = 1; year ++}
            return this.getYearMonth({year:year, month:month});
            
        }
        else return {str:'', int:0};
    },
    getNow(options=[]){
        let return_value = {};
        let now = new Date();
        let datetime = now.toISOString().replace('T',' ').substr(0,19);
        return_value['date_time'] = datetime;
        if (options['get_date']) return_value['date']= datetime.split(' ')[0];

        return return_value;
        
    },
    saveData(data_obj,key){
        let saving_data = {value:data_obj, datetime:this.getNow()}
        localStorage.setItem(key,JSON.stringify(saving_data));
    },
    getData(key){
        let d = localStorage.getItem(key);
        if (d) return (JSON.parse(d).value);
        else return null
    },
    checkData(key){
        let datetime = null;
        if (localStorage.getItem(key) !== null)
        {
            let d = localStorage.getItem(key);
            datetime = JSON.parse(d).datetime;
        }

        return (datetime);
    },
    splitAtIndex(value, index){
        
        return [value.substring(0, index),value.substring(index)];
    },
    convertArrayToObject(array, key){
        const initialValue = {};
        return array.reduce((obj, item) => {
          return {
            ...obj,
            [item[key]]: item,
          };
        }, initialValue);
    }

  }
  
  export default functions