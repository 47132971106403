<template>
<section >
  
  <section class='page-padding-sides mt-20' >

  <!-- <editbox class='mt-10' title='Venue IDs' @enterPressed="enterPressed"></editbox>
  <p class='field-explain'> Please enter one venue id or seperate them by with comma like 501,502 for multiple venues. </p>
  <hr class='mt-20'>
-->
  <div class='panel-view mt-20'>
    <table-s1 title="Order list" :header_titles=header_titles :lines=lines></table-s1>
  </div> 
  </section> 
  

</section>
</template>

<script>


export default {
  components: {
  },
  data(){
    return{
      header_titles : ['Date Time','Order ID', 'Venue', 'Order Status','Delivery Status','Subtotal','Delivery','Total','Venue #', 'User #'],
      lines:[]
    }
  },
  methods:{
    async requestOrder(){
      
        let order_id = this.$route.params.orderid
        console.log(order_id);
        
        // console.log('sending request');
        // let result = await this.$store.dispatch('requests/sendRequest', {
        //   endpoint:'order-list',
        //   params:{ }             
        // });
        
        
        // let result_data = result.list ?? [];
  
        // let array_lines = [];
        // result_data.forEach(element => {
        //   array_lines.push([element.register_date_time ?? '',
        //                     element.order_id ?? '',
        //                     element.venue_name ?? '',
        //                     element.order_status ?? '',
        //                     element.delivery_status ?? '',
        //                     element.subtotal ?? '',
        //                     element.delivery_charge ?? '',
        //                     element.total ?? '',
        //                     element.venue_id ?? '',
        //                     element.user_id ?? '',
                            
                            
                            
        //   ]);
          
        // });
        // this.lines = array_lines;
        // console.log(array_lines);
        
    },

    // async getPageData(kind){
    //   let result = await this.$store.dispatch('requests/sendRequest', {
    //     endpoint:kind,
    //     params:{ }             
    //   });

    //   switch (kind) {
    //     case 'order-count':this.total_orders = result.order_count ? parseInt(result.order_count):0; break;
    //     case 'user-count':this.total_users = result.user_count ? parseInt(result.user_count):0; break;
    //     case 'venue-count':this.total_venues = result.venue_count ? parseInt(result.venue_count):0; break;
    //   }
    // },
    
  },
  mounted(){
    this.requestOrder();
   
  },
}
</script>

<style scoped>
.panel-view {border-radius: 8px; background:#e8eaed; flex: 1 1 auto; padding: 10px;}
</style>