<template>
<section style=''>
  
  <page-header>Test Slerp Connection</page-header>
  <panel>
      <div class='flex flex-space-between gap-20'>
             
            <div style='flex: 0 0 50%' >
                <editbox class='' title='Venue #'  @changed=" value => {venueID = value} "></editbox>
            </div>
      
      </div>
   
  </panel>

  <panel v-show=" test_result.length > 0">
  
    <div class='test-result-title'>Test Result</div>
    <div class='mt-20'>{{test_result}}</div>
    
  </panel>


  <container>
    <div class='flex flex-row-reverse'>
      <button-s1 class='width-200 ml-10' :onClick="testConnectionPressed" >Test Connection</button-s1>
      <button-s1 class='width-200' :onClick="testWebhookPressed" >Test Webhook</button-s1>
    </div>
  </container>

</section>
</template>

<script>

import { useStore } from 'vuex';
import { ref } from 'vue';
export default {

  setup(){
    const store = useStore()
    const venueID = ref('')
    const test_result = ref('')

    const testConnectionPressed = async () => {
      test_result.value = ''
      let params = { venue_id: venueID.value}
      console.log(params);
      
      let result = await store.dispatch('requests/sendRequest', {
        endpoint:'test-online-order-connection',
        params:params             
      });

      if (result['venue_name']) {
        test_result.value = result['venue_name'] + " ---> ";
      }

      if (result['test_result']) {
         test_result.value += result['test_result']
      }
      else test_result.value = 'error performing test'
      
    }

    const testWebhookPressed = async () => {
      test_result.value = ''
      let params = { venue_id: venueID.value}
      console.log(params);
      
      let result = await store.dispatch('requests/sendRequest', {
        endpoint:'test-online-order-webhook',
        params:params             
      });

      if (result['venue_name']) {
        test_result.value = result['venue_name'] + " ---> ";
      }

      if (result['test_result']) {
         test_result.value += result['test_result']
      }
      else test_result.value = 'error performing test'
      
    }
    

    return { venueID , testConnectionPressed, testWebhookPressed , test_result}
  }

}
</script>

<style scoped>
.panel-view {border-radius: 8px; background:#e8eaed; flex: 1 1 auto; padding: 10px;}
.test-result-title {color:red}
</style>