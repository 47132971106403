<template>
    <div class='container'>
        <div class='field-title'>{{title}}</div>
        <select @change="selectChanged($event)">
            <option v-show="firstDisabledOptionTitle != ''" disabled :selected="state.index==-1" >{{firstDisabledOptionTitle}} </option>
            <option v-for="(item,index) in items" :key="index" :selected="state.index==index" >{{item}}</option>
        </select>
        
    </div>
</template>

<script>
import { watch, reactive } from 'vue';
export default {

    props: {
        title:{type: String, default: ''},
        items:{type:Array, default: ()=>{return []}},
        index_value:{type: Number, default: -1},
        firstDisabledOptionTitle:{type: String, default: ''},
        
    },
    emits:['changed','changedi'],
    setup(props,context){
        const state = reactive({
            index : props.index_value
        })
        const selectChanged = (event) => {
            let value = event.currentTarget.value;
            
           
            
            context.emit('changed',value )

            let index = props.items.indexOf(value)

            context.emit('changedi',{value:value, index:index}) 
        }

   

        watch(props, (newValue) => {
            state.index = newValue.index_value
        })

        return { state , selectChanged}
    }
    
    // data(){
    //     return{
    //         // inputValue:'',
    //         // textfield_value:this.bind_value,
    //     } 
    // },
    // watch:{
    //     // bind_value(new_value){
    //     //     this.textfield_value = new_value;
    //     // }
    // },
    // methods:{
    //     ,
    //     // enterPressed(par){
    //     //     this.$emit('enterPressed',par.currentTarget.value)
    //     // }
    // }
}
</script>

<style scoped>
.container {flex:1 1 auto}
select {-webkit-appearance: none; -moz-appearance: none; appearance: none; margin-top:5px;
    width:100%; background: white; padding: 0 10px; font-size:16px; border: 1px solid #E4E6F2;
               box-sizing: border-box; border-radius: 5px; Height: 45px; }
</style>