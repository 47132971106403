<template>
<section>
  <page-header>Schedule Notifications</page-header>

  <panel>
    <section class='page-padding-sides mt-20' >
      <section class='flex'>
        <div class='flex-11 pr-20' >
            
            <editbox class='mt-10 ' title='Notification Title' 
              :bind_value = state.title
              @changed="titleChanges"  />
              
            <editbox class='mt-10 mt-50' title='Notification Text'
              :bind_value = state.text
              @changed="bodyChanges"  />

            <div class='flex gap-20 mt-40'>
              <div class='flex-11'>
                <editbox class='mt-10' title='User IDs' 
                  :bind_value = state.user_ids
                  @changed="userIdsChanges" />
                <p class='field-explain'>seperate user ids with comma </p>
              </div>
              <div class='flex-11'>
                <editbox class='mt-10' title='Venue IDs' 
                  :bind_value = state.venue_ids
                  @changed="venueIdsChanges"/>
                <p class='field-explain'>seperate venue ids with comma </p>
              </div>
            </div>
            
            
        </div>
        <div>
          <div class='field-title'>Schedule Date & Time</div>
          <vc-date-picker class='mt-15' v-model="state.schedule_date" mode='dateTime'></vc-date-picker>
        </div>
      </section>


    
    </section> 
  </panel>

  <error-panel :error_value="state.errorValue" ></error-panel>

  <container class='flex flex-row-reverse'>
      <div class='flex flex-row-reverse '>
          <button-s1 class='width-200' @click="schedulePressed" >Schedule Notification</button-s1>
          <button-s1 class='width-200 mr-10' @click="testPressed" >Test Notification</button-s1>
      </div>
  </container> 


  <!-- <table-s1 class='mt-20' title="Order list" :header_widths=header_widths :header_titles=header_titles :lines=lines></table-s1> -->

</section> 
</template> 

<script>
import { useStore } from 'vuex'
import { reactive } from 'vue'
import { useRouter , useRoute} from 'vue-router'

export default {
  setup(){
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const state = reactive({
      errorValue:'',
      text : '',
      title : '',
      status : 'active',
      user_ids : '',
      venue_ids : '',
      schedule_date: new Date(),
      entityID: route.params.entityID ?? 0
    })


    const titleChanges = value => { state.title = value }
    const bodyChanges = value => { state.text = value}
    const userIdsChanges = value => { state.user_id = value}
    const venueIdsChanges = value => { state.venue_ids = value}
    const replaceCharacters = str => {  
      return str.replaceAll("'","’").replaceAll('"',"’")
    }


    const schedulePressed = async()=>{

      const title = replaceCharacters(state.title)
      const body = replaceCharacters(state.text)
      
      if (title.length <= 0) state.errorValue = "please enter title"
      else if (body.length <= 0) state.errorValue = "please enter body"
      else if (state.venue_ids.length <= 0) state.errorValue = "please enter venue ids"
      else {
          let schedule_date = state.schedule_date.toISOString().replace('T',' ').substring(0,19);
          let sending_params = { 
              body: state.text, 
              title: state.title, 
              schedule: schedule_date, 
              status: state.status, 
              user_ids: state.user_ids, 
              venue_ids: state.venue_ids,
              notification_id: state.entityID
          } 

            console.log({sending_params});

          let reqRes = await store.dispatch('requests/sendApiRequest', {
            endpoint:'notifications/add',
            method:'post',
            ...sending_params
          }); 

          console.log({reqRes});
          if (reqRes.result == "success"){
            router.push('/notification/list')
          }
          else 
            state.errorValue = 'error saving notification.'

          

          // let result = await this.$store.dispatch('requests/sendRequest', {
          //   endpoint:'schedule-notification',
          //   params: sending_params             
          // });
          // console.log(result);
      }
      
    }

    const testPressed = async()=>{
   
      let titleValue = replaceCharacters(state.title)
      let bodyValue = replaceCharacters(state.text)

      if (titleValue.length > 0 && bodyValue.length>0)
      {


        let result = await store.dispatch('requests/sendRequest', {
          endpoint:'send-notification',
          method:'post',
          params:{ body:bodyValue , title:titleValue}             
        });
        // let reqRes = await store.dispatch('requests/sendApiRequest', {
        //     endpoint:'notifications/add',
        //     method:'post',
        //     ...sending_params
        //   }); 

        console.log(result);
        
      }
    }

    const requestEditingNotification = async () => {
        let reqRes = await store.dispatch('requests/sendApiRequest', {
            endpoint:'notifications/list',
            method:'post',
            notification_id : state.entityID
        }); 
        console.log({reqRes});
        

        if (reqRes?.count > 0){
            const notification = reqRes.data[0]
            state.title = notification.title
            state.schedule_date = new Date(notification.schedule)
            state.text = notification.body      
            state.user_ids = notification.user_ids
            state.venue_ids= notification.venue_ids
        }

    }



    if (state.entityID>0){
      requestEditingNotification()
    }

    return {state , store , testPressed , titleChanges , bodyChanges , userIdsChanges , venueIdsChanges , schedulePressed}
  }

//   "Traceback (most recent call last):
//   File "./ApiPV1/ApiPRoute.py", line 105, in process_request
//     return_value = callingFunction()
//   File "./ApiPV1/ApiPNotifications.py", line 30, in notification_send
//     self.send_notification_to_firbase(users=users, title=field_title, body=field_body)
//   File "./ApiPV1/ApiPNotifications.py", line 73, in send_notification_to_firbase
//     print(("Sent immediate firebase message to: ["+', '.join(['%s']*len(emails))+"]") % tuple(emails))
// NameError: name 'emails' is not defined
// "

  // components: {
  // },
  // data(){
  //   return{
  //     text : '',
  //     title : '',
  //     status : 'active',
  //     user_ids : '',
  //     venue_ids : '',
  //     schedule_date: new Date(),
  //     header_titles : ['Date Time','#', 'Status', 'Title','Body','Schedule','Venue #'],
  //     header_widths : [140,40,0,0,0,140,100],
  //     lines:[]
  //   }
  // },
  // mounted(){
  //   this.requestNotifications();
  // },
  // methods:{
  //   titleChanges(value){ this.title = value},
  //   bodyChanges(value){ this.text = value},
  //   userIdsChanges(value){ this.user_id = value},
  //   venueIdsChanges(value){ this.venue_ids = value},
  //   replaceCharacters(str){
  //     return str.replaceAll("'","’").replaceAll('"',"’").replaceAll('%',"percent").replaceAll('&',"and");
  //   },
  //   async testPressed(){
   
  //     let titleValue = this.replaceCharacters(this.title)
  //     let bodyValue = this.replaceCharacters(this.text)

  //     if (titleValue.length > 0 && bodyValue.length>0)
  //     {

  //       let result = await this.$store.dispatch('requests/sendRequest', {
  //         endpoint:'send-notification',
  //         params:{ body:bodyValue , title:titleValue}             
  //       });
  //       console.log(result);
        
  //     }
  //   },
  //   async schedulePressed(){

  //     let titleValue = this.replaceCharacters(this.title)
  //     let bodyValue = this.replaceCharacters(this.text)
  //     let venueIDValue = this.venue_ids

  //     if (titleValue.length>0 && bodyValue.length>0 && venueIDValue.length>0)
  //     {
  //         let schedule_date = this.schedule_date.toISOString().replace('T',' ').substring(0,19);
  //         let sending_params = { 
  //             body:bodyValue, 
  //             title:titleValue, 
  //             schedule:schedule_date, 
  //             status:this.status, 
  //             user_ids:this.user_id, 
  //             venue_ids:venueIDValue} 

  //         let result = await this.$store.dispatch('requests/sendRequest', {
  //           endpoint:'schedule-notification',
  //           params: sending_params             
  //         });
  //         console.log(result);
  //     }
      
  //   },
  //   async requestNotifications(){
      
  //       let result = await this.$store.dispatch('requests/sendRequest', {
  //         endpoint:'list-notification',
  //         params:{ }             
  //       });
        
  //       let result_data = result.list ?? [];
  //       // user_ids','', 'title', "body","schedule", "register_date_time"
  //       let array_lines = [];
  //       result_data.forEach(element => {
  //         array_lines.push([element.register_date_time ?? '',
  //                           element.notification_id ?? '',
  //                           element.status ?? '',
  //                           element.title ?? '',
  //                           element.body ?? '',
  //                           element.schedule ?? '',
  //                           element.venue_ids ?? ''
                            
                            
                            
  //         ]);
          
  //       });
  //       this.lines = array_lines;
  //   }
    
  // },
  
}
</script>

<style scoped>
.panel-view {border-radius: 8px; background:#e8eaed; flex: 1 1 auto; padding: 10px;}
</style>