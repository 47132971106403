<template>
<section style='overflow:hidden'>
  <top-header ></top-header>
  <section class='flex' style='margin-top:75px;' >
 
      <navigation v-if="isLogin" class="nav-bar"></navigation>
      <div class='main-body-container'>
        <router-view class='width-100p' style='height:100%'></router-view>
      </div> 
  </section>
</section>
</template>

<script>
import TopHeader from './components/layout/TopHeader.vue'
import Navigation from './components/layout/Navigation.vue'


export default {
  name: 'App',
  computed:{
    isLogin(){
      return (this.$route.path != '/login');
    }
  },
  components: {
    Navigation,
    TopHeader},
  created(){
    this.$store.dispatch('autoLogin');
  }
}
</script>


<style scoped>
.main-body-container{ height:calc(100vh - 75px); overflow:scroll; flex-grow: 1; }
.nav-bar{height:calc(100vh - 75px); min-width:250px;}
</style>

<style lang="scss">
:root {
  --color-main-color: #090B53;
  --color-light-grey: #DCDBDD;
  --white: #ffffff;
}


@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400,700&display=swap');
*{padding: 0;margin: 0;box-sizing: border-box }
h1{font-size:30px; margin-bottom: 10px;}
body{margin:0; padding:0; background: #F8F8FB; font-family: 'Lato',"Helvetica Neue", Helvetica, Arial, sans-serif;}
a { text-decoration: none; cursor: pointer;}
input {outline: none; }
hr {border: 0; height: 0.5px; background: #E4E5ED;}

.panel-view {border-radius: 8px; background:#e8eaed;  padding: 10px;}
.page-padding-sides {padding-left: 20px; padding-right: 20px; max-width:1200px  }
.page-margin-top {margin-top: 20px;}
.gap-20 {gap:20px}
.page-container {width:100%; padding: 20px; max-width:1240px}

.color-gray {color:grey}
.color-blue {color:blue}

.fs-18 {font-size: 18px !important;}
.items-center{align-items: center}
.flex-justify-center {justify-content: center}
.flex{display: flex}
.flex-column{flex-direction: column}
.flex-space-between {justify-content: space-between}
.flex-row-reverse {flex-direction: row-reverse}
.flex-11{flex: 1 1;}
.flex-11auto{flex: 1 1 auto;}
.text-center{text-align: center}
.overflow-hidden {overflow: hidden;}
.overflow-scroll {overflow: scroll;}

.mt-5 {margin-top: 5px !important}
.mt-10 {margin-top: 10px !important}
.mt-15 {margin-top: 15px !important}
.mt-20 {margin-top: 20px !important}
.mt-30 {margin-top: 30px !important}
.mt-40 {margin-top: 40px !important}
.mt-50 {margin-top: 50px !important}

.mb-5 {margin-bottom: 5px !important}
.mb-10 {margin-bottom: 10px !important}
.mb-20 {margin-bottom: 20px !important}
.mb-30 {margin-bottom: 30px !important}
.mb-40 {margin-bottom: 40px !important}
.mb-50 {margin-bottom: 50px !important}
 
.ml-5 {margin-left: 5px !important}
.ml-10 {margin-left: 10px !important}
.ml-20 {margin-left: 20px !important}

.mr-10 {margin-right: 10px !important}

.p-20 {padding: 20px !important}
.pt-10 {padding-top: 10px !important}
.pr-20 {padding-right: 20px !important}

.fw-500{font-weight: 500 !important;} 
.fs-25{font-size: 25px !important;}

.width-100p {width:100% !important}
.width-150 {width:150px !important}
.width-200 {width:200px !important}

.bgtest{background: blue !important}
.text-s1{font-style: normal; font-weight: 300; font-size: 16px; line-height: 19px; color: rgba(9, 11, 83, 0.5);}
.link-s1{font-style: normal; font-weight: 300; font-size: 16px; line-height: 19px; color: #FF95A5;}
.field-explain {font-size:12px; margin-top:10px; color:darkgrey}
.field-title{font-size: 14px}

.table-s2 { border-collapse: collapse; font-size:12px; width:100%;
  thead {background:#FD464B; color:white; position: sticky;top: 0px; font-size: 12px }
  th, td { height:30px; padding: 0 5px; text-align: left; border-bottom: 1px solid #ddd;}
  tr:hover {background-color:#d3e1e3;}
}
.panel-info-title-value { margin-top:5px;
  >div:nth-child(1) {color:darkgray; font-size:12px;}
  >div:nth-child(2) {color:black; font-size:14px; margin-left: 10px; margin-top:-2px;}
}


input {-webkit-appearance: none; -moz-appearance: none; appearance: none; margin-top:5px;
    width:100%; background: white; padding: 0 10px; font-size:16px; border: 1px solid #E4E6F2;
               box-sizing: border-box; border-radius: 5px; Height: 45px; }

</style>
