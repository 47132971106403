<template>
    <main >
        <div class='field-title'>{{title}}</div>

        
        <div class=' ' >
            <vc-date-picker  v-model="state.date_s" :popover="{ visibility: 'click' }">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input  :value="inputValue" v-on="inputEvents"  />
                  </template>
            </vc-date-picker>
  
        </div>
        
    </main>
</template>

<script>
import { reactive  , watch } from 'vue'
// import { useStore } from 'vuex'
// // emit values: selected (bool) , value (string)
export default {       

    props: {
        title:{ type: String, default: ''},
        placeHolder:{ type: String, default: ''},
        date:{ type: Date, default: null},
         
    },
    emits:['changed'], //date1 date2 kind
    setup(props,context){
        // const store = useStore()
        const state = reactive({
            date_s: props.date ? props.date : new Date()
        })
 

        // ***** actions
        watch(state, (newValue) => {
            context.emit('changed',newValue.date_s )
        });

        watch(props, (newValue) => {
            state.date_s = newValue.date ? newValue.date : new Date()
        });
        
        return { state }
    }
    
}
</script>

<style scoped  lang="scss">

input {-webkit-appearance: none; -moz-appearance: none; appearance: none; margin-top:5px;
    width:100%; background: white; padding: 0 10px; font-size:16px; border: 1px solid #E7E8EC;
               box-sizing: border-box; border-radius: 5px; height: 40px; }

</style>