<template>
<section style='display:flex; flex-direction:column;  '>
  SubscriptionReportPayment
    
</section>
</template>

<script>
// import { reactive } from 'vue'
 
// import useSubHook from './SubscriptionHook.js'

//@change="stateValueChanged('sendDate',$event)"

import { useStore } from 'vuex'


export default {
  components: {
  },
  setup(){
     const store = useStore()
    const getData = async () => {
    


        let reqResSubs = await store.dispatch('requests/sendApiRequest', {
            endpoint:'subscriptions/stripe-payments',
            method:'post'
        }); 
        console.log({reqResSubs});
        
      
    }



    getData()
       
  }

}
</script>

