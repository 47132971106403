<template>
<section style=''>
  
  <page-header>Generate Stampcode</page-header>
  <panel>
          Search
  </panel>


  <table-s1 class='mt-20' title="Order list" :header_titles=table_header_titles :lines=table_lines></table-s1>


</section>
</template>

<script>


export default {
  components: {
  },
  data(){
    return{
      table_header_titles : ['id ','Stamp Code', 'Scheme #', 'Venue #','User #','Unique','Number of Stamps', 'Status',''],
      table_lines:[]
    }
  },
  methods:{
    async getStampCodeList(){
      
        let params = {
        }
        
        
        let result = await this.$store.dispatch('requests/sendRequest', {
          endpoint:'stampcodes-list',
          params:params             
        });

        this.analyseTableData(result.list ?? [])
        
    },
    analyseTableData(result_data){
      
      this.table_lines = [];
      let active = {'0':'Inactive', '1':'Active', '2':'Claimed'};

      result_data.forEach(line => {
        this.table_lines.push([
          line.stamp_code_id,
          line.stamp_code_str, 
          line.loyalty_scheme_id, 
          (line.venue_id==0) ? '-':line.venue_id,
          (line.user_id==0) ? '-':line.user_id,
          line.unique == 1 ? 'Yes':'No',
          line.stamp_number, 
          active[line.status],
          [{type:'icon-button', link:'/stampcodes/edit/'+line.stamp_code_id, icon:'edit'}]
        ]);
      });
    }
    
  },
  mounted(){
    this.getStampCodeList();
  }
}
</script>

<style scoped>

</style>