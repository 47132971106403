<template>

    <section class='main-container ' :style="'left: '+left+'px'">

        <div class='header-container flex flex-space-between items-center'>
            <div class='table-header'>{{values.name}}</div>
            <div :class=" 'table-tag ' + table_action "  ></div>
        </div>

        <div v-for="item in values.items" :key="item.field" class='filed-container'>
            <div class='flex flex-space-between'>
                <div class='table-field ' :class = " (item.action == 'remove') ?'action-remove':((item.action == 'add') ?'action-add':'')" >
                    {{item.field}} <span v-if="item.action == 'remove'"> (remove)</span>
                </div>
                <div class='table-kind'>{{item.kind}}</div>
            </div>
            <div v-if="'note' in item" class='table-field-note'> {{item.note}} </div>
            <hr class='lines'>
        </div>
        <slot></slot>

        <div v-if=" table_description != '' "  class='table-field-note'><pre> {{table_description}}</pre> </div>

       
       
    </section>

</template>

<script>
import { reactive , computed } from 'vue'

export default {
    props: {
    // table_name: { type: String },
        values: {type:Object, default: ()=>{return {}}},
    // table_items: {type:Array, default: ()=>{return []}},
    // table_description: { type: String, default:'' },
    
    },
    setup(props){
       
        const state = reactive({
            
        });

        const table_action = computed (()=>{
            if (props.values.action) 
                return'background-'+props.values.action;
            else return '';
        }) 

// const title1 = computed(() => props.values.title ?? '-')

        return {state , table_action}
    }
}
</script>

<style scoped>
.main-container{font-size:12px;width:250px; height:auto;  overflow: hidden; background: #FFFFFF; border:1px solid #ccc; border-radius: 10px; }

.header-container{background:#ccc;padding:0 4px;height:30px;width:100%;  }
.table-header {font-size: 12px;  color:rgb(38, 37, 37)}
.table-tag {width:10px; height:10px; border-radius: 50%; }
.background-add {background:blue}
.background-remove {background:red}
.table-field {margin: 5px}
.table-kind {font-style: italic; margin: 5px; color:#666; font-size:12px;}
.lines {background:rgb(232, 231, 231); margin: 0 5px}
.table-field-note {font-size: 10px; margin: 0 5px 5px 5px; color:#666}
.action-remove{color:red!important}
.action-add{color:blue!important}
</style>