import { createStore } from 'vuex';

import requestsModule from './modules/requests/index.js';
import authModule from './modules/auth/index.js';

const store = createStore({
  modules: {
    requests: requestsModule,
    auth: authModule,
  },
  // getters:{
  //   api_url(){
  //       if (process.env.NODE_ENV == 'development') return 'http://dev.embargoapp.com:5000/api/p/v1/';
  //       else if (process.env.NODE_ENV == 'production') return 'https://prod.embargoapp.com/api/p/v1/';
  //       else return '';
  //     }
  // }
});

export default store;