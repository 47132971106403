export default {
  setUser(state, params) {
    localStorage.setItem('token',params.token);
    localStorage.setItem('user_id',params.user_id);

    state.token = params.token;
    state.user_id = params.user_id;
  },
  removeUser(state) {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');


    state.token = null;
    state.user_id = null;
  }
};