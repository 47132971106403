
<template>
<section >
    
   <ul class='main-nav mt-30'>
    <li v-for="(nav_item, index) in nav_items" :key="nav_item.title" 
        @mouseover="nav_item.hover = true"
         >
         <div class='flex items-center'>
             <!-- <div class='icon-container'> -->
             <!-- <img :src="require('@/assets/'+nav_item.icon+'.svg')"> -->
             <!-- </div> -->
  
             <a @click="navItemClicked(index)" class='nav-title ml-10'>{{ nav_item.text }}</a>
         </div> 
        
         <transition name = 'nav-trans'
                    @enter="navTransEnter"
                    @after-enter="navTransAfterEnter"
                    @before-leave="navTransBeforeLeave"
                    @after-leave="navTransAfterLeave">
            <ul v-if="nav_item.is_open" class='pt-10'>
                <li v-for="(nav_sub_item,sub_index) in nav_item.items" :key="nav_sub_item.text">
                   <a @click="navSubItemClicked(index,sub_index)" :key="nav_sub_item.text" class='nav-sub-title'> {{ nav_sub_item.text }} </a>
                </li>
            </ul>
         </transition>
    </li>
   </ul>

</section>
</template>

<script>

export default {
    data(){
        return{
            isShow:false,
            nav_items:[
                {text:'Requests', icon:'', is_open:false, items:[
                    
                    {text:'Request List', link:'/requests/list'}
                    
                ]},

                {text:'Statistics', icon:'icn_report_white', is_open:false, items:[
                    {text:'Online Orders',link:'/statistics/online-orders'},
                    {text:"New Monthly Venues",link:'/statistics/venues/monthly-growth'},
                    {text:"Venues User Acquisition",link:'/statistics/venues/monthly-user-acquisition'}]},

                {text:'Venues', icon:'icn_venue_white', is_open:false, items:[
                    {text:'Venue Users', link:'/venues/users'},
                    {text:'Venue User Growth',link:'/venues/user/growth'}], },
                
                {text:'Notifications', icon:'icn_task', is_open:false, items:[
                    {text:'New Notificatoin', link:'/notification/new'},
                    {text:'Notifications', link:'/notification/list'}]},
                {text:'Promocodes', icon:'icn_task', is_open:false, items:[
                    {text:'Generate Promocode', link:'/promocodes/generate'},
                    {text:'Promocode List', link:'/promocodes/list'},]},
                    
                {text:'Stampcodes', icon:'icn_venue_white', is_open:false, items:[
                    {text:'Generate Stampcode', link:'/stampcodes/generate'},
                    {text:'Stampcode List',link:'/stampcodes/list'}], },
                {text:'Portal Users', icon:'icn_venue_white', is_open:false, items:[
                    {text:'New Portal User', link:'/portal-user/new'},
                    {text:'Portal User List',link:'/portal-user/list'}], },
                {text:'Tasks', icon:'icn_task', is_open:false, items:[
                    
                    {text:'Slerp Test', link:'/online-order-test'},
                    // {text:'Users', link:'/user/list'},
                    {text:'Logs', link:'/logs'},
                    {text:'Database', link:'/database'},
                    
                    ]},

                {text:'Subscription', icon:'icn_task', is_open:false, items:[
                    
                    
                    {text:'Subscription List', link:'/subscriptions/list'},
                    {text:'Subscription Report', link:'/subscriptions/report'},
                    {text:'Stripe Data', link:'/subscriptions/stripe-data'},
                    
                    // {text:'Subscription Payments', link:'/subscriptions/payments'},

                    
                    
                    ]},

                

                    
            ]
        } 
    },
    methods:{
        navItemClicked(index){
            this.nav_items[index].is_open = !this.nav_items[index].is_open;
        },
        navSubItemClicked(index,sub_index){
            let link = this.nav_items[index]['items'][sub_index]['link'];
            if (link.length && link.length > 0)
            {
                this.$router.push(link);
            }
            
            
            
            
        },
        navTransEnter(element) {
                element.style.height = element.scrollHeight + 'px'
        },
        navTransAfterEnter(element) {
            element.style.height = ''
        },
        navTransBeforeLeave(element) {
            element.style.height = element.scrollHeight + 'px'
        },
        navTransAfterLeave(element) {
            element.style.height = ''
        },
    }

}
</script>

<style scoped lang="scss">
// section{background: linear-gradient(180deg, #FD4646 25%, rgba(253, 65, 107, 0.23) 100%); width: 230px; height: 100vh;}
section{background:   #FD4646}
ul{ list-style:none; padding-left: 20px; padding-bottom: 10px;
    li{ margin-top: 10px; cursor: pointer; margin-bottom: 20px;
        ul{margin-left:15px}
        a.nav-title{color: white; transition: all 0.2s ease-out;}
        a.nav-sub-title{color: #f3efef; transition: all 0.2s ease-out;} 
        :hover{
            a.nav-title{color: #090B53}
            // a.nav-sub-title{color: red; } 
            .icon-container{animation: animation-icon 0.2s ease-out;;}
        }
    }
}

.icon-container{display: flex; align-items: center; justify-content: center; width: 35px; height: 35px; background: rgb(243, 241, 241); border-radius: 5px; margin-right: 10px;
  img { 
      width:20px; height: 20px; margin-right: 10px; fill: red;
  }
}

@keyframes animation-icon {
  0%{ transform: rotate(5deg);}
  50%{ transform: rotate(-5deg);}
  100%{ transform: rotate(0deg);}
}
</style>