<template>
<section>
  <page-header>Notifications</page-header>
  <table-s1 class='mt-20' title="Order list" :header_titles=state.header_titles :lines=state.lines :header_widths=[0,0,0,0,130,100,70,0] />
</section> 
</template> 

<script>
import { reactive } from 'vue'
import { useStore } from 'vuex'

export default {
  setup(){
    const store = useStore()
    const state = reactive({
      header_titles : ['#', 'Status', 'Title','Body','Schedule','Venue IDs', 'User IDs' , ''],
      lines:[]
    })

    const requestNotifications = async () => {
      
        // let reqRes = await store.dispatch('requests/sendRequest', {
        //         endpoint:'list-notification',
        //   params:{ } 
        // }); 

        let reqRes = await store.dispatch('requests/sendApiRequest', {
            endpoint:'notifications/list',
            method:'post'
        }); 

       
        let result_data = reqRes.data ?? [];
 
        let array_lines = [];
        result_data.forEach(element => {
          array_lines.push([element.notification_id ?? '',
                            element.status ?? '',
                            element.title ?? '',
                            element.body ?? '',
                            element.schedule ? trimDate(element.schedule):'',
                            element.venue_ids ?? '',
                            element.user_ids ?? '',
                            (element.status == 'active') ? [{type:'icon-button', link:'/notification/edit/'+element.notification_id, icon:'edit'}] : ''
                            
                            
                            
          ]);
          
        });
        state.lines = array_lines;
    }

    const trimDate = (date) => {
      let str = date.replace('T',' ').substring(0,19)
      return str
    }
    requestNotifications()

    return {state , requestNotifications} 

  }
  
}
</script>
