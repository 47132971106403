<template>
<section style=''>
  
  <page-header>Venue's Users</page-header>
  <panel>
      <editbox class='mt-10' title='Venue IDs' @enterPressed="enterPressed"></editbox>
      <p class='field-explain'> Please enter one venue id or seperate them by with comma like 501,502 for multiple venues. </p>
  </panel>

  <table-s1 title="Users list" :header_titles=header_titles :lines=lines></table-s1>

</section>
</template>

<script>


export default {
  components: {
  },
  data(){
    return{
      header_titles : ['ID','Name','Email'],
      lines:[]
    }
  },
  methods:{
    async enterPressed(value){
      if (value.length > 0)
      {
        console.log('sending request');
        let result = await this.$store.dispatch('requests/sendRequest', {
          endpoint:'venue-user',
          params:{ venue_ids:value}             
        });

        let result_data = result.list ?? [];
        let array_lines = [];
        result_data.forEach(element => {
          let id = element.id ?? '';
          let email = element.email ?? '';
          let name = element.name ?? '';
          array_lines.push([id,name,email]);
        });
        this.lines = array_lines;
        
      }
      
    },
    
  }
}
</script>
