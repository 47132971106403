<template>
<main>
  <page-header>Stripe Raw Data</page-header>
  <panel>
      <div class='flex gap-20'>

        <div class='flex-11'>
          <editbox class='mt-10' title='Customer ID' @enterPressed="requestStripeCustomerData"/>
          <p class='field-explain'> Please enter stripe's cusmer id like cus_****</p>
        </div>

        <div class='flex-11'>
          <editbox class='mt-10' title='Subscription ID' @enterPressed="requestStripeSubscriptionData"></editbox>
          <p class='field-explain'> Please enter stripe's subscriptoin id like sub_****</p>
        </div>

        <div class='flex-11'>
          <editbox class='mt-10' title='Payment ID' @enterPressed="requestStripePaymentData"></editbox>
          <p class='field-explain'> Please enter stripe's subscriptoin id like sub_****</p>
        </div>

      </div>
  </panel>

  <div style='color:green; margin-left:20px; margin-right:20px; max-width:1200px'>
    <pre>
{{state.json}} 
    </pre>
  </div>

</main>
</template>

<script>
// import { reactive } from 'vue'
 
import useSubHook from './SubscriptionHook.js'
import { reactive } from 'vue'


export default {
  components: {
  },
  setup(){
    const state = reactive({
      json: ''
    })
    
    const {getStripeJsonData} = useSubHook(state)

    const requestStripeCustomerData = (value) => {
      getStripeJsonData({cusID:value});
    }

    const requestStripeSubscriptionData = (value) => {
      getStripeJsonData({subID:value});
    }

    const requestStripePaymentData = (value) => {
      getStripeJsonData({paymentID:value});
    }
    
    return {state  , requestStripeCustomerData , requestStripeSubscriptionData , requestStripePaymentData}
  }

}
</script>

<style lang="scss" scoped>


</style>