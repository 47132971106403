<template>
<section style=''>
  
  <page-header>Generate Promocode</page-header>
  <panel>
      <div class='flex flex-space-between gap-20'>
        <div class='flex-11'>
            <list-view title="Promocode Items" 
                :header_titles="['Venue ID','Offer ID']" 
                :items_type=state.promo_items_type
                :items_value=state.promo_items_value
                @itemsValueChanged="itemsValueChanged"></list-view>
            <button-s1 class='style2 mt-10' :onClick="addLinePressed" >Add line</button-s1>
        </div>

        <div class='flex-11'>

          <div class='flex flex-space-between gap-20'>
            <div class='flex-11'>
                <editbox class='' :bind_value="state.name" title='Promocode Name'  @changed="nameChanged"></editbox>
            </div>

            <div class='flex-11'>
                <select-box title='Is promocode Unique?' :items="['Yes','No']" @changed="uniqueChanged"></select-box>
            </div>
          </div>


          <div class='flex flex-space-between gap-20 mt-50'>
            <div class='flex-11'>
              <editbox class='' :bind_value="state.code" title='Code'  @changed="codeChanged"></editbox>
              <p class='field-explain'> Leave empty for random code </p>
            </div>

            <div class='flex-11'>
              <editbox :bind_value="state.code_number" min="1" max="50" title='Number of Codes' @changed="codeNumberChanged"></editbox>
              <p class='field-explain'> Enter a number between 1 and 50</p>
            </div>
          </div>


        </div>

         
      </div>
  </panel>

 <error-panel :error_value="state.error_value" ></error-panel>

<container>
    <div class='flex flex-row-reverse'>
      <button-s1 class='width-200' :onClick="savePromoPressed" >Save Promocode</button-s1>
    </div>
</container> 
 <panel v-if="state.received_promocodes.length > 0">
    Promo codes have been generated successfully.<br><br>
    <ul class='ml-20'>
      <li v-for="promo in state.received_promocodes" :key="promo">
        {{promo}}
      </li>
    </ul>
</panel> 



  

</section>
</template>

<script>
import { reactive } from 'vue'
import { useStore } from 'vuex'

export default {
  setup(){
    const store = useStore()
    const state = reactive({
      received_promocodes: [],
      promo_items_value:[ ['',''] ],
      promo_items_type:[ {type:'number-edit-box'},  {type:'number-edit-box'},  ],
      name:'',
      code:'',
      code_number:1,
      unique:1,
      error_value:''
    })

    const nameChanged = (received_value) => { state.name = received_value }
    const uniqueChanged = (received_value) => { state.unique = (received_value=='No')? 0:1;}
    const codeChanged = (received_value) => { state.code = received_value }
    const codeNumberChanged = (received_value) => { state.code_number = received_value }
    const itemsValueChanged = (received_value) => {

      console.log('itemsValueChanged',received_value.value);
      state.promo_items_value = received_value.value;
    }


    const savePromoPressed = async () => {
      state.error_value = ''

      

      if (state.name.length == 0) state.error_value = 'promocode name is required'
      else if (state.code.length != 0 && state.code_number > 1) state.error_value = 'When code is not random, number of codes should be 1'
      else 
      {


          let items = {}
          state.promo_items_value.forEach(line => {
            let i = parseInt(line[0])
            let j = parseInt(line[1])
            
            if (i>0 && j>0){
              items[i] = j
            }
          });

        
          
          if (Object.keys(items).length == 0) {state.error_value = 'There is no promocode item'; return}

          let params = {
            name: state.name,
            items: JSON.stringify(items),
            code: state.code.replace(/\s/g, ''),
            unique: state.unique, 
            number_of_codes: state.code_number 
          }
          console.log(params);

          let reqRes = await store.dispatch('requests/sendApiRequest', {
              endpoint:'promocodes/add',
              method:'post',
              params:params     
          }); 
          console.log(reqRes);


          if (reqRes.result == 'error'){
            state.error_value = reqRes.error_message ?? 'error.'
          } else if (reqRes.added_promocodes){
            state.received_promocodes = reqRes.added_promocodes

            
            state.promo_items_value = [ ['',''] ]
            state.promo_items_type = [ {type:'number-edit-box'},  {type:'number-edit-box'},  ]
            state.name = ''
            state.code = ''
            state.code_number = 1
            state.unique = 1
          }




      }
      
    }
    
    const addLinePressed = () => {
      state.promo_items_value.push(['','']);
      
    }

    return {
      state , addLinePressed , savePromoPressed , 
      nameChanged , uniqueChanged , codeChanged , codeNumberChanged , itemsValueChanged
    }

  }

  // data(){
  //   return{

  //   }
  // },
  // methods:{
  //   
  //   
  //   
  //   
    
  //   ,
    
  // }
}
</script>

<style scoped>
.panel-view {border-radius: 8px; background:#e8eaed; flex: 1 1 auto; padding: 10px;}
</style>