<template>
<section >
  <page-header>Online Orders</page-header>
  <table-s1 class='mt-20' title="Order list" :header_titles=header_titles :lines=lines></table-s1>

  <section class='page-padding-sides mt-20' >
 
    
  </section> 
  

</section>
</template>

<script>


export default {
  components: {
  },
  data(){
    return{
      header_titles : ['Date Time','Order ID', 'Venue', 'Order Status','Delivery Status','Subtotal','Delivery','Total','Venue #', 'User #',''],
      lines:[]
    }
  },
  methods:{
    async requestOrders(){
      
        let result = await this.$store.dispatch('requests/sendRequest', {
          endpoint:'order-list',
          params:{ }             
        });
        
        let result_data = result.list ?? [];
  
        let array_lines = [];
        result_data.forEach(element => {
          array_lines.push([element.register_date_time ?? '',
                            element.order_id ?? '',
                            element.venue_name ?? '',
                            element.order_status ?? '',
                            element.delivery_status ?? '',
                            element.subtotal ?? '',
                            element.delivery_charge ?? '',
                            element.total ?? '',
                            element.venue_id ?? '',
                            element.user_id ?? '',
                            [{type:'icon-button', link:'/vene', icon:'edit'}]
                            
                            
                            
          ]);
          
        });
        this.lines = array_lines;
    },

    // async getPageData(kind){
    //   let result = await this.$store.dispatch('requests/sendRequest', {
    //     endpoint:kind,
    //     params:{ }             
    //   });

    //   switch (kind) {
    //     case 'order-count':this.total_orders = result.order_count ? parseInt(result.order_count):0; break;
    //     case 'user-count':this.total_users = result.user_count ? parseInt(result.user_count):0; break;
    //     case 'venue-count':this.total_venues = result.venue_count ? parseInt(result.venue_count):0; break;
    //   }
    // },
    
  },
  mounted(){
    this.requestOrders();
   
  },
}
</script>

<style scoped>
.panel-view {border-radius: 8px; background:#e8eaed; flex: 1 1 auto; padding: 10px;}
</style>