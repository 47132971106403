<template>
<section class='ml-10 mt-10'>
    <div class='tables-main-container'>
    
      <DatabaseTable :values="state.table_user" ></DatabaseTable>
  
      <DatabaseTable :values="state.table_reward" ></DatabaseTable>
  
      <DatabaseTable :values="state.table_offers" ></DatabaseTable>
    
      <DatabaseTable :values="state.table_offer_overrides"><div class='ml-10 mt-10 color-gray'> will be covered in userActions.  </div></DatabaseTable>
    
      <DatabaseTable :values="state.table_tokens" >
            <div class='ml-10 mt-10 color-gray'> to confirm or reset accounts. </div>
            <ul class='mt-10'>     
              <li>when a user registers an account, a row will be added to this table and a confirmation link will be sent to the user.</li>
              <li>when a user requests a reset password, a row will be added to this table and a link with a token will be sent to the user.</li>
              <li>the row will be removed after the first access to invalidate the next request.</li>
              <li>expired rows will be removed from the table every day.</li>
            </ul>
      </DatabaseTable> 

      <DatabaseTable  :values="state.table_user_points" >
            <div class='ml-10 mt-10 color-gray'> to keep users’ point history.  </div>
            <ul class='mt-10'>    

              <li>a row will be added for stamp collection (point can be more than one).</li>
              <li>a row will be added for every claim.</li>
              <li>adjustments are possible with the type (3).</li>
              <li>IMPORTANT: rows can not be deleted or modified, any changes should be registered in a new row..</li>
            </ul>
      </DatabaseTable> 
      <DatabaseTable  :values="state.table_limited_offers" ></DatabaseTable> 
      
   
      <DatabaseTable :values="state.table_user_actions" >
            <div class='ml-10 mt-10 color-gray'> to keep users’ rewards, promocodes, stampcodes,  giftcard codes, favourites.  </div>
            <ul class='mt-10'> 
              <li>A new row will be added for every offer user can claim (type 0)</li>
              <li>Type 0 can not be deleted or modified</li>
              <li>A new row will be added for venue id cards</li>
              <li>A new row will be added for every promo code claim</li>
              <li>A new row will be added for every stamp code claim</li>
              <li>A new row will be added for every gift card claim</li>
            </ul>
      </DatabaseTable>
      
      <DatabaseTable :values="state.table_notifications"  ></DatabaseTable>
      <DatabaseTable :values="state.table_events"  ></DatabaseTable>
      <DatabaseTable :values="state.table_adjust" ></DatabaseTable>
      <DatabaseTable :values="state.table_claims" ><div class='ml-10 mt-10 color-gray'> will be covered in userActions.  </div></DatabaseTable>
      <DatabaseTable :values="state.table_loyalty_schemes" ></DatabaseTable>
      <DatabaseTable :values="state.table_progression" ><div class='ml-10 mt-10 color-gray'> will be covered in userPoints.</div></DatabaseTable>
      <DatabaseTable :values="state.table_words" ></DatabaseTable>
      <DatabaseTable :values="state.table_promotions" ></DatabaseTable>
      <DatabaseTable :values="state.table_promotions_overrides"><div class='ml-10 mt-10 color-gray'> will be covered in userActions.</div></DatabaseTable>
      <DatabaseTable :values="state.table_stats" ><div class='ml-10 mt-10 color-gray'> will be covered in userPoints.</div></DatabaseTable>
      <DatabaseTable :values="state.table_venues" ></DatabaseTable>
      <DatabaseTable :values="state.table_notification_schedule" ></DatabaseTable>
      <DatabaseTable :values="state.table_stamp_codes" ></DatabaseTable>
      <DatabaseTable :values="state.table_stamp_code_claims" ></DatabaseTable>
      <DatabaseTable :values="state.table_orders" ></DatabaseTable>
      <DatabaseTable :values="state.table_order_status" ></DatabaseTable>
      <DatabaseTable :values="state.table_order_items" ></DatabaseTable>
  


            
    
  </div>

</section> 
</template> 

<script>

import { onMounted, reactive } from 'vue'
export default {
  setup(){

    const state = reactive({
            table_user: {name:'', items:[]},
            table_reward: {name:'', items:[]},
            table_offers: {name:'', items:[]},
            table_tokens: {name:'', items:[]},
            table_user_points: {name:'', items:[]},
            table_limited_offers: {name:'', items:[]},
            table_user_actions: {name:'', items:[]},
            table_offer_overrides: {name:'', items:[]},
            table_adjust: {name:'', items:[]},
            table_events: {name:'', items:[]},
            table_notifications: {name:'', items:[]},
            table_claims : {name:'', items:[]},
            table_loyalty_schemes : {name:'', items:[]},
            table_progression : {name:'', items:[]},
            table_words : {name:'', items:[]},
            table_promotions: {name:'', items:[]},
            table_promotions_overrides: {name:'', items:[]},
            table_stats : {name:'', items:[]},
            table_venues: {name:'', items:[]},
            table_notification_schedule : {name:'', items:[]},
            table_stamp_codes : {name:'', items:[]},
            table_stamp_code_claims : {name:'', items:[]},
            table_orders : {name:'', items:[]},
            table_order_status : {name:'', items:[]},
            table_order_items: {name:'', items:[]},
    });
    

    const createUsersArray = () => {
      state.table_user = {
        name: 'Users',
        items: [
          {field:"id", kind:"int"},
          {field:"email", kind:"varchar"},
          {field:"name", kind:"varchar"},
          {field:"gender", kind:"varchar"},
          {field:"password", kind:"varchar"},
          {field:"registered", kind:"double"},
          {field:"referralCode", kind:"varchar"},
          {field:"birthday", kind:"varchar"},
          {field:"picture", kind:"varchar"},
          {field:"firebase", kind:"varchar"},
          {field:"version", kind:"double"},
          {field:"identifier", kind:"varchar"},
          {field:"system", kind:"varchar"},
          {field:"device", kind:"varchar"},
          {field:"lastIP", kind:"varchar"},
          {field:"gdpr", kind:"boolean"},
          {field:"tracker", kind:"varchar"},
          {field:"appleID", kind:"varchar"},
          {field:"appleAccessToken", kind:"varchar"},
          {field:"appleRefreshToken", kind:"varchar"},
          {field:"contactNumber", kind:"varchar"},
          {field:"addressLine1", kind:"varchar"},
          {field:"addressLine2", kind:"varchar"},
          {field:"addressCity", kind:"varchar"},
          {field:"addressCountry", kind:"varchar"},
          {field:"addressPostcode", kind:"varchar"},
          {field:"rtimestamp", kind:"timestamp"},
          {field:"utimestamp", kind:"timestamp"},
          {field:"resetCode", kind:"varchar", note:"will go to token table", action:"remove"},
          {field:"approved", kind:"boolean", note:"will be covered in status", action:"remove"},
          {field:"confirmationCode", kind:"varchar", note:"will go to token table", action:"remove"},
          {field:"promotions", kind:"varchar", note:"will go to UserActions table", action:"remove"},
          {field:"favorites", kind:"varchar", note:"will go to UserActions table", action:"remove"},
          {field:"blacklisted", kind:"boolean", note:"will be covered in status", action:"remove"},
          {field:"unfavorited", kind:"varchar", note:"will go to UserActions table", action:"remove"},
          {field:"status", kind:"varchar", note:"0: not confirmed user, 1: active user(confirmed), 2: inactive user(blacklisted)", action:"add"}
        ]
      }

      state.table_reward = {
        name:'Rewards',
        items:[
          {field:"id", kind:"int"},
          {field:"name", kind:"varchar"},
          {field:"description", kind:"varchar"},
          {field:"icon", kind:"varchar"},
          {field:"image", kind:"varchar"},
          {field:"uses", kind:"int"},
          {field:"requiredInitialPoints", kind:"int"},
          {field:"requiredSeparatePoints", kind:"int"},
          {field:"status", kind:"int"},
          {field:"cooldown", kind:"int"},
          {field:"teaser", kind:"varchar"},
          {field:"condition", kind:"varchar"},
          {field:"categories", kind:"varchar"},
          {field:"timeTags", kind:"varchar"},
          {field:"rtimestamp", kind:"timestamp"},
          {field:"utimestamp", kind:"timestamp"}
        ]
      }
      state.table_offers = {
        name:'Offers',
        items:[
          {field:"id", kind:"int"},
          {field:"description", kind:"varchar"},
          {field:"icon", kind:"varchar"},
          {field:"type", kind:"varchar", action:"remove"},
          {field:"uses", kind:"int"},
          {field:"hidden", kind:"boolean "},
          {field:"startTime", kind:"varchar"},
          {field:"endTime", kind:"varchar"},
          {field:"startDate", kind:"varchar"},
          {field:"endDate", kind:"varchar"},
          {field:"days", kind:"varchar"},
          {field:"requiredInitialPoints", kind:"int"},
          {field:"requiredSeparatePoints", kind:"int"},
          {field:"active", kind:"boolean"},
          {field:"instruction", kind:"varchar"},
          {field:"cooldown", kind:"boolean"},
          {field:"teaser", kind:"varchar"},
          {field:"image", kind:"varchar"},
          {field:"condition", kind:"varchar"},
          {field:"categories", kind:"varchar"},
          {field:"timeTags", kind:"varchar"},
          {field:"created", kind:"real", note:"no need any more", action:"remove"},
          {field:"venueID", kind:"int", note:"will go to VenueOffers", action:"remove"},
          {field:"loyaltySchemeID", kind:"int", note:"will go to VenueOffers", action:"remove"},
          {field:"replacedBy", kind:"int", note:"no need any more", action:"remove"},
          {field:"name", kind:"varchar", action:"add"},
          {field:"note", kind:"varchar", action:"add"},
          {field:"rtimestamp", kind:"timestamp", action:"add"},
          {field:"utimestamp", kind:"timestamp", action:"add"},


        ]
      }

      state.table_tokens = {
        name:'Tokens (New)',
        action:"add",
        items:[
          {field:"id", kind:"int", action:"add"},
          {field:"user_id", kind:"int", action:"add"},
          {field:"type", kind:"smallint", action:"add"},
          {field:"token", kind:"varchar", action:"add"},
          {field:"rtimestamp", kind:"timestamp", action:"add"},
          {field:"* index:user_id", kind:"timestamp", action:"add"},
        ]
      }
      
      
      state.table_limited_offers = {
        name:'LimitedOffers (New)',
        action:"add",
        items:[
          {field:"id", kind:"int", action:"add"},
          {field:"venue_id", kind:"int", action:"add"},
          {field:"offer_id", kind:"int", action:"add"},
          {field:"rtimestamp", kind:"timestamp", action:"add"}

        ]
      }

      state.table_user_points = {
        name:'UserPoints (New)',
        action:"add",
        items:[
          {field:"id", kind:"int", action:"add"},
          {field:"user_id", kind:"int", action:"add"},
          {field:"venue_id", kind:"int", action:"add"},
          {field:"point", kind:"smallint", action:"add"},
          {field:"type", kind:"smallint", note:"0: collect stamp, 1:claim reward, 2: manual adjustment", action:"add"},
          {field:"dtimestamp", kind:"timestamp", note:"device timestamp", action:"add"},
          {field:"rtimestamp", kind:"timestamp", action:"add"},
          {field:"* index: user_id,venue_id", kind:"", action:"add"},

        ]
      }

      state.table_user_actions = {
        name:'UserActions (New)',
        action:"add",
        items:[
          {field:"id", kind:"int", action:"add"},
          {field:"user_id", kind:"int", action:"add"},
          {field:"venue_id", kind:"int", action:"add"},
          {field:"entity_code", kind:"varchar", action:"add"},
          {field:"type", kind:"smallint", note:"0: rewards, 1: favourite venues, 2:promocode, 3:stampcode, 4:giftcard", action:"add"},
          {field:"dtimestamp", kind:"timestamp", note:"device timestamp", action:"add"},
          {field:"rtimestamp", kind:"timestamp", action:"add"},
          {field:"* index: user_id,venue_id", kind:"", action:"add"},
        ]
      }

      state.table_offer_overrides = {
        name:'OfferOverrides (Remove)',
        action:"remove",
        items:[
          {field:"id", kind:"int", action:"remove"},
          {field:"offerID", kind:"int", action:"remove"},
          {field:"hidden", kind:"boolean", action:"remove"},
          {field:"uses", kind:"int", action:"remove"},
          {field:"requiredInitialPoints", kind:"int",  action:"remove"},
          {field:"userID", kind:"int", action:"remove"},
        ]
      }

      state.table_adjust = {
        name:'Adjust (Remove)',
        action:"remove",
        items:[
          {field:"id", kind:"int", action:"remove"},
          {field:"networkName", kind:"varchar", action:"remove"},
          {field:"deviceName", kind:"varchar", action:"remove"},
          {field:"deviceInternetServiceProvider", kind:"varchar", action:"remove"},
          {field:"deviceIPAddress", kind:"varchar", action:"remove"},
          {field:"event", kind:"varchar", action:"remove"},
          {field:"mobileNetworkCode", kind:"int", action:"remove"},
          {field:"city", kind:"varchar", action:"remove"},
          {field:"installTime", kind:"int", action:"remove"},
          {field:"country", kind:"varchar", action:"remove"},
          {field:"appVersion", kind:"varchar", action:"remove"},
          {field:"tracker", kind:"varchar", action:"remove"},
          {field:"deviceType", kind:"varchar", action:"remove"},
          {field:"mobileCountryCode", kind:"int", action:"remove"},
          {field:"postalCode", kind:"varchar", action:"remove"},
          {field:"adid", kind:"varchar", action:"remove"},
          {field:"store", kind:"varchar", action:"remove"},
          {field:"clickTime", kind:"int", action:"remove"},
          {field:"campaignName", kind:"varchar", action:"remove"},
          {field:"eventTime", kind:"double", action:"remove"},
          {field:"idfa", kind:"varchar", action:"remove"},
          {field:"androidID", kind:"varchar", action:"remove"},
          {field:"adgroupName", kind:"varchar", action:"remove"},
          {field:"creativeName", kind:"varchar", action:"remove"},
        ]
      }


      state.table_events = {
        name:'Events (Remove)',
        action:"remove",
        items:[
            {field:"id", kind:"int", action:"remove"},
            {field:"email", kind:"varchar", action:"remove"},
            {field:"time", kind:"double", action:"remove"},
            {field:"event", kind:"varchar", action:"remove"},
            {field:"value1", kind:"varchar", action:"remove"},
            {field:"value2", kind:"varchar", action:"remove"},
            {field:"value3", kind:"varchar", action:"remove"},
            {field:"value4", kind:"varchar", action:"remove"},
            {field:"value5", kind:"varchar", action:"remove"},
        ]
      }

      state.table_notifications = {
        name:'Notifications',
        items:[
              {field:"id", kind:"int"},
              {field:"tag", kind:"varchar"},
              {field:"status", kind:"boolean"},
              {field:"time", kind:"double"},
              {field:"userID", kind:"int"},
              {field:"venueID", kind:"int"},
              {field:"loyaltySchemeID", kind:"int"},
              {field:"offerID", kind:"int"},
              {field:"title", kind:"varchar"},
              {field:"text", kind:"varchar"},
              {field:"type", kind:"varchar"},
              {field:"templateID", kind:"varchar"},
        ]
      }

      state.table_claims = {
        name:'Claims (remove)',
        action:'remove',
        items:[
              {field:"id", kind:"int", action:'remove'},
              {field:"offerID", kind:"int", action:'remove'},
              {field:"venueID", kind:"int", action:'remove'},
              {field:"processed", kind:"double", action:'remove'},
              {field:"confirmed", kind:"double", action:'remove'},
              {field:"userID", kind:"int", action:'remove'},
        ]
      }

      state.table_loyalty_schemes = {
        name:'LoyaltySchemes',
        items:[
                {field:"id", kind:"int"},
                {field:"name", kind:"varchar"},
                {field:"isGroup", kind:"boolean"},
                {field:"scheme", kind:"varchar"},
                {field:"pointsCycle", kind:"int"},
                {field:"active", kind:"boolean"},
                {field:"logo", kind:"varchar"},
                {field:"image", kind:"varchar"},
                {field:"colour1", kind:"varchar"},
                {field:"colour2", kind:"varchar"},
                {field:"colour3", kind:"varchar"},
                {field:"textColour1", kind:"varchar"},
                {field:"textColour2", kind:"varchar"},
                {field:"textColour3", kind:"varchar"},
                {field:"borderColour", kind:"varchar"},
        ]
      }

      state.table_progression = {
        name:'Progression (remove)',
        action:"remove",
        items:[
                {field:"id", kind:"int", action:"remove"},
                {field:"venueID", kind:"int", action:"remove"},
                {field:"time", kind:"double", action:"remove"},
                {field:"points", kind:"int", action:"remove"},
                {field:"received", kind:"double", action:"remove"},
                {field:"userID", kind:"int", action:"remove"},
        ]
      }

      state.table_words = {
        name:'Words (remove)',
        action:"remove",
        items:[
                {field:"code", kind:"varchar", action:"remove"},
        ]
      }

      state.table_promotions = {
        name:'Promotions',
        items:[
             {field:"id", kind:"int"},
            {field:"code", kind:"varchar"},
            {field:"description", kind:"varchar"},
            {field:"referral", kind:"boolean", action:"remove"},
            {field:"exceptions", kind:"varchar"},
            {field:"expiration", kind:"varchar"},
            {field:"unique", kind:"smallint", action:"remove"},
            {field:"venue_id", kind:"int", action:"add"},
            {field:"use_number", kind:"tinyint", action:"add"},
        ]
      }

      state.table_promotions_overrides = {
        name:'PromotionOverrides',
        action:"remove",
        items:[
              {field:"id", kind:"int", action:"remove"},
              {field:"offerID", kind:"int", action:"remove"},
              {field:"referral", kind:"boolean", action:"remove"},
              {field:"identifier", kind:"varchar", action:"remove"},
              {field:"time", kind:"double", action:"remove"},
              {field:"promotionID", kind:"int", action:"remove"},
              {field:"userID", kind:"int", action:"remove"},
        ]
      }

      state.table_stats = {
        name:'Stats (remove)',
        action:"remove",
        items:[
                {field:"venueID", kind:"int", action:"remove"},
                {field:"release", kind:"double", action:"remove"},
                {field:"level", kind:"int", action:"remove"},
                {field:"points", kind:"int", action:"remove"},
                {field:"userID", kind:"int", action:"remove"},
                {field:"rtimestamp", kind:"timestamp", action:"remove"},
                {field:"utimestamp", kind:"timestamp", action:"remove"},
        ]
      }

      state.table_venues = {
        name:'Venues',
        items:[
            {field:"id", kind:"int"},
            {field:"name", kind:"varchar"},
            {field:"description", kind:"varchar"},
            {field:"openingHours", kind:"varchar"},
            {field:"latitude", kind:"double"},
            {field:"longitude", kind:"double"},
            {field:"street", kind:"varchar"},
            {field:"city", kind:"varchar"},
            {field:"country", kind:"varchar"},
            {field:"zip", kind:"varchar"},
            {field:"area", kind:"varchar"},
            {field:"timezone", kind:"varchar"},
            {field:"images", kind:"varchar"},
            {field:"categories", kind:"varchar"},
            {field:"beacons", kind:"varchar"},
            {field:"active", kind:"boolean"},
            {field:"released", kind:"boolean"},
            {field:"launch", kind:"varchar"},
            {field:"cuisines", kind:"varchar"},
            {field:"priceLevel", kind:"int"},
            {field:"pointLimit", kind:"int"},
            {field:"pointPeriod", kind:"int"},
            {field:"distanceLimit", kind:"int"},
            {field:"loyaltySchemeID", kind:"int"},
            {field:"orderlink", kind:"varchar"},
            {field:"mappingVanueID", kind:"varchar"},
            {field:"onlineOrderingCommission", kind:"numeric"},
            {field:"pointAmountFormula", kind:"numeric"},
            {field:"contactNumber", kind:"varchar"},
            {field:"currency", kind:"int"},
        ]
      }

      state.table_notification_schedule = {
        name:'NotificationSchedule',
        items:[
          {field:"id", kind:"int"},
          {field:"created_by", kind:"int"},
          {field:"userIDs", kind:"varchar"},
          {field:"venueIDs", kind:"varchar"},
          {field:"title", kind:"varchar"},
          {field:"body", kind:"varchar"},
          {field:"status", kind:"varchar"},
          {field:"rtimestamp", kind:"timestamp"},
          {field:"utimestamp", kind:"timestamp"},
          {field:"schedule", kind:"timestamp"},
        ]
      }

      state.table_stamp_codes = {
        name:'StampCodes',
        items:[
          {field:"stamp_code_id", kind:"int"},
          {field:"user_id", kind:"int"},
          {field:"venue_id", kind:"int"},
          {field:"loyalty_scheme_id", kind:"int"},
          {field:"stamp_number", kind:"int"},
          {field:"unique", kind:"int"},
          {field:"rtimestamp", kind:"timestamp"},
          {field:"utimestamp", kind:"timestamp"},
          {field:"status", kind:"int"},
          {field:"stamp_code_str", kind:"varchar"},
        ]
      }

      state.table_stamp_code_claims = {
        name:'StampCodeClaims',
        items:[
          {field:"stamp_code_claim_id", kind:"int"},
          {field:"stamp_code_id", kind:"int"},
          {field:"user_id", kind:"int"},
          {field:"rtimestamp", kind:"timestamp"},
          {field:"utimestamp", kind:"timestamp"},
        ]
      }

      state.table_orders = {
        name:'Orders',
        items:[
          {field:"id", kind:"int"},
          {field:"orderID", kind:"varchar"},
          {field:"status", kind:"varchar"},
          {field:"subtotal", kind:"numeric"},
          {field:"total", kind:"numeric"},
          {field:"deliveryCharge", kind:"numeric"},
          {field:"transactionID", kind:"varchar"},
          {field:"orderNotes", kind:"varchar"},
          {field:"currencyKind", kind:"smallint"},
          {field:"theirVenueID", kind:"varchar"},
          {field:"ourVenueID", kind:"int"},
          {field:"userID", kind:"int"},
          {field:"appVersion", kind:"varchar"},
          {field:"recipient", kind:"varchar"},
          {field:"jsonString", kind:"text"},
          {field:"customerID", kind:"varchar"},
          {field:"customerEmail", kind:"varchar"},
          {field:"customerContactNumber", kind:"varchar"},
          {field:"fulfillmentDate", kind:"varchar"},
          {field:"deliveryEta", kind:"varchar"},
          {field:"theirRegisterAt", kind:"varchar"},
          {field:"rtimestamp", kind:"timestamp"},
          {field:"utimestamp", kind:"timestamp"},
          {field:"orderQty", kind:"numeric"},
          {field:"discount", kind:"numeric"},
          {field:"orderType", kind:"varchar"},
          {field:"tax", kind:"numeric"},
          {field:"payment", kind:"text"},
          {field:"point", kind:"int"},
          {field:"venueName", kind:"varchar"},
          {field:"addressLat", kind:"numeric"},
          {field:"addressLng", kind:"numeric"},
          {field:"addressLine1", kind:"varchar"},
          {field:"addressLine2", kind:"varchar"},
          {field:"addressCity", kind:"varchar"},
          {field:"addressCountry", kind:"varchar"},
          {field:"addressPostcode", kind:"varchar"},
          {field:"delivery_status", kind:"varchar"},
          {field:"order_status", kind:"varchar"},
          {field:"orderStatus", kind:"varchar"},
          {field:"deliveryStatus", kind:"varchar"},
          {field:"venueEmail", kind:"varchar"},
          {field:"venueContactNumber", kind:"varchar"},
        ]
      }

      state.table_order_status = {
        name:'OrderStatus',
        items:[
          {field:"id", kind:"int"},
          {field:"transactionID", kind:"varchar"},
          {field:"orderStatus", kind:"varchar"},
          {field:"deliveryStatus", kind:"varchar"},
          {field:"rtimestamp", kind:"timestamp"},
          {field:"utimestamp", kind:"timestamp"},
        ]
      }

      state.table_order_items = {
        name:'OrderItems',
        items:[
          {field:"id", kind:"int"},
          {field:"parentID", kind:"int"},
          {field:"linkID", kind:"int"},
          {field:"orderID", kind:"varchar"},
          {field:"qty", kind:"numeric"},
          {field:"total", kind:"numeric"},
          {field:"unitPrice", kind:"numeric"},
          {field:"tax", kind:"numeric"},
          {field:"discount", kind:"numeric"},
          {field:"name", kind:"varchar"},
          {field:"itemID", kind:"varchar"},
          {field:"rtimestamp", kind:"timestamp"},
          {field:"utimestamp", kind:"timestamp"},
        ]
      }


    }

    onMounted(()=>{
      createUsersArray();
    })

    return {
      state
    }
  }
  
}
</script>

<style scoped lang="scss">
.panel-view {border-radius: 8px; background:#e8eaed; flex: 1 1 auto; padding: 10px;}

ul{ list-style:lower-alpha; padding-left: 20px; padding-bottom: 10px;
    li{ margin-top: 10px; cursor: pointer; margin-bottom: 10px; color:grey
        
    }
}

.tables-main-container {display:flex; flex-direction: column; width:100%; height: 95vh; flex-wrap: wrap; gap:10px;}
</style>