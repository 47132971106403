<template>
   <section>
       <div class='content-container'>
            <div class='fw-500 fs-25 '>Login {{ $store.getters.getUrl }}</div>
          
            <div class='flex mt-10'>
                <div class='text-s1 '>Don't have an account yet?</div>
                <a class='link-s1 ml-5' :href="'mailto:reza@embargoapp.com'">request an account</a>
            </div>

            <hr class='mt-40 mb-40'>

            <div class='form-container'>
              <editbox class='mt-10 mr-10 ' title='User Name' @changed="usernameChanged">
              </editbox>

              <editbox class='mt-10' title='Password' type='password' @changed="passwordChanged">
              </editbox>
            </div>

            <div class='button-container'>
                <button-s1 :onClick="loginAction">Login</button-s1>
            </div>

            <div v-show="!!error_value" class='error-container'>
                {{error_value}}
            </div>

            <hr class='mt-40 mb-40'>

            <div class='flex mt-10'>
                <div class='text-s1 '>Forgotten your password?</div>
                <a class='link-s1 ml-5' :href="'mailto:reza@embargoapp.com'">request a new password</a>
            </div>
            
       </div>
   </section>
</template>

<script>

export default {
    data(){
        return{
            error_value: '',
            userName:'',
            password:''
        }
    },
    computed:{
        ccc() {
            console.log('sss');
            return this.$store.getters.getCC;
        }
    },
    methods:{
        async loginAction() {
            // this.formIsValid = true;
            // if (
            //     this.email === '' ||
            //     !this.email.includes('@') ||
            //     this.password.length < 6
            // ) {
            //     this.formIsValid = false;
            //     return;
            // }

            // this.isLoading = true;

            
            try {
                let a = await this.$store.dispatch('login', {
                    user_name: this.userName,
                    password: this.password,
                });
                if (a) 
                  this.$router.replace('/home');
                
                // const redirectUrl = '/' + (this.$route.query.redirect || 'coaches');
                // this.$router.replace(redirectUrl);
            } catch (err) {
                this.error = err.message || 'Failed to authenticate, try later.';
            }

            // this.isLoading = false;
        },
        usernameChanged(value){ this.userName = value},
        passwordChanged(value){ this.password = value},

        
        
        
    }
         
        
}
</script>

<style scoped>
section{display: flex; justify-content: center;  width:100%; height: 100vh;}
.content-container{width:90%; margin: 50px 5% 0 5%;  }

.button-container {display: flex; margin-top: 20px; flex-direction: row-reverse }
.error-container{text-align: center; widows: 100%; color: #FD464B}
.form-container {width:100%}
@media (min-width: 820px) {
    .content-container{width:800px; margin: 200px auto 0 auto; }
    .form-container {display: flex; }
}
</style>

