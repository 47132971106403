<template>
<section>
  <panel>
    <div class='flex'>
        <div><vc-date-picker class='' v-model="log_date" mode='date'></vc-date-picker></div>
        <div class='ml-20'>
          <div class='flex'>
            <button-s1 class='width-200' @click="logPressed('error')" > Error Log</button-s1>
            <button-s1 class='width-200 ml-10' @click="logPressed('dev')" > Dev Log</button-s1>
          </div>

          <div class='flex mt-10'>
            <button-s1 class='width-200' @click="logPressed('notification')" > Notifications Log</button-s1>
            <button-s1 class='width-200 ml-10' @click="logPressed('order')" > Orders Log</button-s1>
          </div>

          <div class='flex mt-10'>
            <button-s1 class='width-200 ' @click="logPressed('stampcode')" > Stampcodes Log</button-s1>
            <button-s1 class='width-200 ml-10' @click="logPressed('webhooktest')" > Slerp Webhook Test</button-s1>
          </div>

        </div>
    </div>
  </panel>
  <container><p class="log-text" v-html="log_text" /></container>
</section> 

</template> 

<script>
export default {
  components: {
  },
  data(){
    return{
      log_text:'',
      date: new Date(),
      inputValue : '2020',
      log_date: new Date()
    }
  },
  mounted(){
    this.requestLog('dev');
  },
  methods:{
    logPressed(type){
        this.requestLog(type)
    },
    async requestLog(type){
      
        let date = this.log_date.toISOString().split('T')[0];
         
        let result = await this.$store.dispatch('requests/sendRequest', {
          endpoint:'get-log',
          params:{ type:type, date:date}             
        });
        let received_text = result.log_text ?? 'log request is not available or empty.';
        received_text = received_text.replaceAll('\n','<br>')
        received_text = received_text.replaceAll('<*1','<span style="color:blue">')
        received_text = received_text.replaceAll('<*2','<span style="color:red">')
        received_text = received_text.replaceAll('*>','</span>')
        
        this.log_text = received_text
    
    },

    
  },
  
}
</script>

<style scoped>
.log-text{font-size: 12px;}
</style>