<template>
    <div class='container'>
        <div class='field-title'>{{title}}</div>
        
        <div v-if="type == 'date-picker'"> 
            <vc-date-picker v-model="date">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input class="" :value="inputValue" v-on="inputEvents"/>
                  </template>
            </vc-date-picker>  
        </div>
        
        <div v-else>
            <input :readonly="readonly" :type="type" :value="textfield_value" v-on:keyup="inputChanged" v-on:keyup.enter="enterPressed"/>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        
        title:{ type: String, default: ''},
        type:{ type: String, default: 'text'},
        readonly:{type: Boolean, default:false},
        do_not_show_zero:{type: Boolean, default:false},
        default:{ type: String, default: ''},
        identifier:{ type: String, default: ''},
        bind_value:{ type: String, default: ''},
        min:{ type: Number, default: -1},
        max:{ type: Number, default: -1},

        default_date:{ type: Date, default: function () { return new Date() } }, 
    },
    emits:['changed','enterPressed','input'],
    data(){
        return{
            date: this.default_date,
            inputValue:'',
            // textfield_value:this.bind_value,
        } 
    },
    watch:{
        // bind_value(new_value){
        //     this.textfield_value = new_value;
        // },
        date(new_value){
            this.$emit('changed',new_value)
            console.log(new_value);
        },
    },
    computed:{
        textfield_value(){
            let return_value = this.bind_value;
            if (this.do_not_show_zero && (return_value == 0)) return_value = '';
 
            return return_value;
        }
    },
    methods:{
        inputChanged(par){
            
            let this_value = par.currentTarget.value;
            if (this.min != -1){
                if (parseInt(this_value) < this.min) {this.textfield_value = this.min; this_value = this.min;}
            }
        
            if (this.max != -1){
                if (parseInt(this_value) > this.max) {this.textfield_value = this.max; this_value = this.max;}
            }
            
        
        
        
            if (this.identifier.length > 0)
                this.$emit('changed',{value: this_value, identifier: this.identifier})
            else
                this.$emit('changed',this_value)

            
        },
        enterPressed(par){
            
            
            if (this.identifier.length > 0)
                this.$emit('enterPressed',{value: par.currentTarget.value, identifier: this.identifier})
            else
                this.$emit('enterPressed',par.currentTarget.value)
        }
    }
}
</script>

<style scoped>
.container {flex:1 1 auto}

</style>