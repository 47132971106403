

import { useStore } from 'vuex'
// import functions from '../../functions'
import useHookHelper from './hook-helper'

export default function useHook(state) {
    state.panelData = {infos:[]}

    const store = useStore()
    const helper = useHookHelper(state)
    
    const getRequests = async () => {
       
        let reqRes = await store.dispatch('requests/sendApiRequest', {
            endpoint:'requests/management-list',
            method:'post',
            request_type:'all'
        }); 

        helper.parseResponse(reqRes)
    }

    

    const lineClicked = (index) => {
        let requestID = state.lines[index][0]
        showDetails(requestID)
    }

    const showDetails = (requestID) => {
        const thisObj = state.requestsObj[requestID]

        state.panelData.infos = []
        state.panelData.infos.push(['Type',helper.getType(thisObj.kind ?? 0)])
        state.panelData.infos.push(['Status',helper.getStatus(thisObj.status ?? 0)]) 
        
        state.panelData.requestID = requestID
        helper.addSpecificRequestInfo(thisObj)
    }

    const panelButtonClicked = async (type) => {
        
        let status = -1
        switch (type) {
            case 'approve': status = 1;break;
        }

        if (status >= 0){
            const requestID = state.panelData.requestID
            let reqRes = await store.dispatch('requests/sendApiRequest', {
                endpoint:'requests/change-status',
                request_id:requestID,
                status:status
            }); 

            if (reqRes.result == "success"){
                state.requestsObj[requestID].status = status
                location.reload()
            }
            
        }
        
    }

    return { getRequests , lineClicked , panelButtonClicked }


    
    
}
