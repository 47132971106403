<template>
<section style=''>
  
  <page-header> {{$route.params.codeid ? "Edit Stampcode" : "Generate Stampcode"}}</page-header>
  <panel>
      
          <div class='flex flex-space-between gap-20'>
            <div class='flex-11'>
                <editbox class='' title='Loyalty Scheme ID' :bind_value="scheme_id" @changed="val => scheme_id = val" type="number" do_not_show_zero="true"></editbox>
            </div>

            <div class='flex-11'>
                <editbox class='' title='Venue ID' :bind_value="venue_id" @changed="val => venue_id = val" type="number" do_not_show_zero="true"></editbox>
                <p class='field-explain'> Venue ID will be ignored if loyalty scheme has been set.</p>
            </div>

            <div class='flex-11'>
                <editbox class='' title='User ID'  :bind_value="user_id" @changed="val => user_id = val" type="number" do_not_show_zero="true"></editbox>
                <p class='field-explain'> Setting user id will limit the code to a specific user.</p>
            </div> 
          </div>




          <div class='flex flex-space-between gap-20 mt-50'>
            <div class='flex-11'>
                <select-box title='Is promocode Unique?' :index_value="unique" :items="['No','Yes']" @changed="val => unique = val"></select-box>
                <p class='field-explain'> Unique codes can be applied only once.</p>
            </div>

         
            <div class='flex-11'>
              <editbox min="1" max="10" title='Number of Stamps' :bind_value="stamp_number" @changed="val => stamp_number = val" type="number"></editbox>
              <p class='field-explain'> Enter a number between 1 and 10.</p>
            </div>

            <div class='flex-11' >
                <select-box title='Status' :index_value="status" :items="['Inactive','Active']" @changed="val => status = val"></select-box>
                <p class='field-explain'> inactive codes can not be applied.</p>
            </div>
          </div>

          <div v-if="!$route.params.codeid">
            <hr class='mt-40'>

            <div class='flex flex-space-between gap-20 mt-40'>
              <div class='flex-11'>
                <editbox bind_value="1" min="1" max="50" title='How many codes to generate?' @changed="numberOfCodesChanged" type="number"></editbox>
                <p class='field-explain'> Enter a number between 1 and 50.</p>
              </div>

          
              <div class='flex-11'>
              </div>

              <div class='flex-11' >
              </div>
            </div>
          </div>


  </panel>

  <panel v-if="received_codes.length > 0">
    Stampcodes have been generated successfully.<br><br>
    <ul class='ml-20'>
      <li v-for="code in received_codes" :key="code">
        {{code}}
      </li>
    </ul>
  </panel>

  <error-panel :error_value="error_value" ></error-panel>

  <container v-if="received_codes.length == 0">
    <div class='flex flex-row-reverse'>
      <button-s1 class='width-200' :onClick="savePromoPressed" >Generate {{number_of_codes}} Stampcode(s)</button-s1>
    </div>
  </container>


</section>
</template>

<script>


export default {
  components: {
  },
  data(){
    return{
      scheme_id:0,
      venue_id:0,
      user_id:0,
      unique:1,
      stamp_number:1,
      status:1,
      received_codes: [],
      error_value:'',
      number_of_codes:1,
    }
  },
  mounted(){
    this.getPageData();
  },
  watch: {
    $route() {
      this.getPageData();
    }
  },
  methods:{
    loyaltySchemeIDChanged(received_value){ this.scheme_id = received_value },
    venueIDChanged(received_value){ this.venue_id = received_value },
    userIDChanged(received_value){ this.user_id = received_value },
    uniqueChanged(received_value){ this.unique = (received_value=='No')?'0':'1';},
    stampNumberChanged(received_value){ this.stamp_number = received_value },
    statusChanged(received_value){ this.status = (received_value=='Inactive')?'0':'1';},
    numberOfCodesChanged(received_value){ this.number_of_codes = received_value },
    async getPageData(){

        let codeid = this.$route.params.codeid ?? 0;

        if (codeid > 0)
        {
          let params = {
            stamp_code_id : codeid
          }
          
          let result = await this.$store.dispatch('requests/sendRequest', {
            endpoint:'stampcodes-list',  
            // method:'get',       
            params:params             
          });

          
          if (result.list && (result.list.length > 0))
          {
            let stampcode_date = result.list[0];
            this.scheme_id = stampcode_date.loyalty_scheme_id;
            this.venue_id = stampcode_date.venue_id;
            this.user_id = stampcode_date.user_id;
            this.unique = stampcode_date.unique;
            this.stamp_number = stampcode_date.stamp_number;
            this.status = stampcode_date.status;
          }
        }
        
        
    },
    async savePromoPressed(){

      this.error_value = '';
      if (this.venue_id == 0) this.error_value = 'You need to set Loyalty Scheme ID or Venue ID.';
      else 
      {
          let params = {
            stamp_code_id: this.$route.params.codeid ?? 0,
            scheme_id: this.scheme_id,
            venue_id: this.venue_id,
            user_id: this.user_id,
            unique: this.unique,
            stamp_number: this.stamp_number,
            number_of_codes:this.number_of_codes,
            status: this.status
          }
        
          
          let result = await this.$store.dispatch('requests/sendRequest', {
            endpoint:'stampcodes-generate',
            params:params             
          });

          console.log(result);
          
          this.received_codes = result.list;
          
      }
      
    },
    
  }
}
</script>

<style scoped>

</style>