


import functions from '../../functions'

export default function useHookHelper(state) {
    
    const getStatus = (status) => {
        let statusStr = ''
          switch (status) {
            case 0: statusStr = 'Pending'; break;
            case 1: statusStr = 'Approved'; break;
          }
        return statusStr
    }
    const getType = (type) => {
        let typeStr = ''
        switch (type) {
            case 0: typeStr = 'Unknown'; break;
            case 1: typeStr = 'Notification'; break;
            case 2: typeStr = 'Limited Offer'; break;
            case 3: typeStr = 'promocode'; break;
            case 4: typeStr = 'giftcard'; break;
            case 5: typeStr = 'email'; break;
            case 6: typeStr = 'stampcode'; break;
        }
        return typeStr
    }

    const parseResponse = (reqRes) => {
        console.log({reqRes});
    
        let result_data = reqRes.data ?? [];

        let array_lines = [];
        state.requestsObj = functions.convertArrayToObject(result_data,'id')
        result_data.forEach(element => {
        
        array_lines.push([
            element.id ?? '-',
            element.venues ?? '-',
            getType(element.kind ?? 0),
            getStatus(element.status ?? 0),
        ]);
        
        });
        state.lines = array_lines;
    }

    const addSpecificRequestInfo = (thisObj) => {
        const data = JSON.parse(thisObj.json ?? '')
        console.log({data});
        const info = state.panelData.infos
        
        if (thisObj.kind == 1){

            info.push([])
            info.push(['Venue ID', data.venue_ids ?? ''])
            info.push(['Venue Names', data.venue_names ?? ''])
            info.push(['Title Type', data.title_text ?? ''])
            info.push(['Gender', data.gender_text ?? ''])
            info.push(['Journey', data.journey_text ?? ''])
            info.push(['Activity', data.recent_activity_text ?? ''])
            info.push(['Schedule', data.schedule_date ?? ''])

            info.push([])
            info.push(['Message', data.message ?? ''])
        }

    }

    return { getStatus , getType , parseResponse , addSpecificRequestInfo}


    
    
}
