<template>
<section>
  <page-header>Portal Users</page-header>

  <panel>
    <section class='page-padding-sides mt-20' >
      
        <div class='flex gap-20 mt-20'>
          <div  style='flex:1 1 50%'>
            <editbox class='mt-10' title='Full Name' :bind_value=state.name   @changed="nameChanged" />
          </div>
          <div  style='flex:1 1 50%; ' class='flex-column'>
            <editbox class='mt-10' title='Password' subTitle="this is des" :bind_value=state.password @changed="passwordChanged"></editbox>
            <p class='field-explain'> {{ (state.entityID>0) ? `leave password empty if you do not want to change the current password`:`leave empty for random generated password`}}</p> 
          </div>
        </div>



        <div class='flex gap-20 mt-40'>
          <editbox class='mt-10' title='Email' :bind_value=state.email @changed="emailChanged"></editbox>
          <editbox class='mt-10' title='Venue ids' :bind_value=state.venues @changed="venuesChanged"></editbox>
        </div>

        
    </section> 
  </panel>

  <error-panel :error_value="state.error_value" ></error-panel>

  <container class='flex flex-row-reverse'>
      <div class='flex flex-row-reverse '>
          <button-s1 class='width-200' @click="requestAddPressed"> {{state.entityID>0?"EDIT USER":"ADD USER"}}</button-s1>
      </div>
  </container>


</section> 
</template> 

<script>
import { reactive } from 'vue'
import { useRoute } from 'vue-router'

import useHook from './portal-user-hook.js'

export default {

  setup(){
        const route = useRoute()
        const state = reactive ({
            name: '',
            email:'',
            venues:'',
            password:'',
            passwordDescription:'leave password empty if you do not want to change password',
            error_value:'',
            entityID: route.params.entityID ?? 0
        })
     
        const nameChanged = (value) => {state.name = value}
        const emailChanged = (value) => {state.email = value}
        const venuesChanged = (value) => {state.venues = value}
        const passwordChanged = (value) => {state.password = value}

        const {requestAddPressed,requestUser } = useHook(state)

        if (state.entityID>0) requestUser(state.entityID)

        return { state , nameChanged , emailChanged , venuesChanged  , requestAddPressed ,  passwordChanged}
  }
  
}
</script>

<style scoped>
.panel-view {border-radius: 8px; background:#e8eaed; flex: 1 1 auto; padding: 10px;}
</style>