import { createApp } from 'vue'

import App from './App.vue';
import store from './store/index.js';
import router from './router.js';

import Editbox from './components/elements/Editbox.vue'
import Selectbox from './components/elements/Selectbox.vue'
import ButtonS1 from './components/elements/ButtonS1.vue'
import ButtonIcon from './components/elements/ButtonIcon.vue'

import DataViewer from './components/elements/DataViewer.vue'
import TableS1 from './components/elements/TableS1.vue'
import PageHeader from './components/elements/PageHeader.vue'
import Panel from './components/layout/Panel.vue'
import Container from './components/layout/Container.vue'
import ListView from './components/elements/ListView.vue'
import ErrorPanel from './components/layout/ErrorPanel.vue'
import DatabaseTable from './components/elements/DatabaseTable.vue'
import Radio from './components/elements/Radio.vue'
import DateBox from './components/elements/DateBox.vue'

import VCalendar from 'v-calendar'; // https://vcalendar.io

const app = createApp(App)

app.use(router);
app.use(store);


app.component('DatabaseTable',DatabaseTable);
app.component('Editbox',Editbox);
app.component('button-s1',ButtonS1);
app.component('data-viewer',DataViewer);
app.component('table-s1',TableS1); 
app.component('page-header',PageHeader);
app.component('panel',Panel);
app.component('error-panel',ErrorPanel);
app.component('container',Container);
app.component('select-box',Selectbox);
app.component('list-view',ListView);
app.component('button-icon',ButtonIcon);
app.component('radio',Radio);
app.component('date-box',DateBox);




app.use(VCalendar, {
    componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

// import Modal from './plugins/PopUp.js'
// app.use(Modal)

app.mount('#app');



