<template>
<section>
  <page-header>Portal Users</page-header>

  <table-s1 class='mt-20' title="Order list" :header_titles=state.header_titles :lines=state.lines></table-s1>

</section> 
</template> 

<script>
import { reactive } from 'vue'

import useHook from './portal-user-hook.js'

export default {
  setup(){
    const state = reactive({
      header_titles : ['#','Name','Email', 'Venues', 'Permission',''],
      lines:[]
    })

    const {requestUserList } = useHook(state)

    requestUserList()

    return {state} 
  }
  
}
</script>
