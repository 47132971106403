<template>
<section style='display:flex; flex-direction:column;  '>
  <page-header>Requests</page-header>

  <!-- <table-s1 class='mt-20' title="Order list" 
     :header_titles=state.header_titles :lines=state.lines :header_widths=[0,0,0,0,0,0,100,100] /> -->
<div class='page-container flex-11 overflow-hidden' style='display:flex; flex-direction:column;'>
   <div class='flex overflow-hidden ' style='flex: 1 1; '>

      <div class='overflow-scroll flex-11 left-container mr-10' style='height:100%'  >
        
        <table class='table-s2'>
            <thead >
              <tr>
                <td>Request #</td>
                <td>Venue IDs</td>
                <td>Type</td>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
                <tr v-for="(line_item,line_index) in state.lines" :key="line_index" @click=lineClicked(line_index)>
                    <td v-for="(item,index_column) in line_item" :key="index_column"   >
                      {{item}}
                    </td>
                </tr>
            </tbody>
          
        </table> 

      </div>



      <div class='overflow-hidden flex-11 right-container' style="max-width:40%">
        <div style='height:100%; overflow:scroll; '>
          
              <div v-for="(line,index) in state.panelData.infos" :key=index>
                <div v-if="line.length == 0" class='mt-20'></div>
                <div v-else class='flex panel-info-title-value'><div>{{line[0]}}:</div> <div> {{line[1]}}</div></div>
              </div> 

              <div class='mt-40' v-show=" state.panelData.infos.length > 0 ">
              <button-s1 class='width-200 mt-10' @click="panelButtonClicked('approve') " ><div class='text-center'>Approve</div></button-s1>
              <!-- <button-s1 class='width-200 mt-10' @click="panelButtonClicked('approve_add') " ><div class='text-center'>Approve & Add</div></button-s1>-->
              <button-s1 class='width-200 mt-10' @click="panelButtonClicked('reject') " ><div class='text-center'>Reject</div></button-s1>
              </div>


              <!-- <div class='flex' style='gap:10px'>
                {{state.subShowPanel}}
                <button-s1 v-show="((state.panelData.subscription && state.panelData.subscription != '') || state.panelData.subscription == '') && state.subShowPanel == ''" class='width-200 mt-10' @click="panelMainButtonClicked('edit-type') " ><div class='text-center'>Edit Type</div></button-s1>
                
                <button-s1 v-show="state.panelData.subscription == '' && state.subShowPanel == ''" class='width-200 mt-10 ' @click="panelMainButtonClicked('add-subscription') " ><div class='text-center'>Add Subscription</div></button-s1>
                <button-s1 v-show="state.panelData.subscription == '' && state.subShowPanel == ''" class='width-200 mt-10 '  @click="panelMainButtonClicked('include-in-subscription') " > <div class='text-center'>Include in Subscription</div></button-s1>
                
                <button-s1 v-show="state.panelData.subscription && state.panelData.subscription != '' && state.subShowPanel == ''" class='width-200 mt-10 ' @click="panelMainButtonClicked('edit-subscription') " ><div class='text-center'>Edit Subscription</div></button-s1>
                <button-s1 v-show="state.panelData.subscription && state.panelData.subscription != '' && state.subShowPanel == ''" class='width-200 mt-10 ' @click="panelMainButtonClicked('delete-subscription') " ><div class='text-center'>Delete Subscription</div></button-s1>
              </div>
              


              <div v-if="state.savingStatus.length > 0" class='text-center mb-20 mt-20 color-blue'>{{state.savingStatus}}</div>
              <div v-else>
                
                  <div v-show="state.subShowPanel == 'edit-type'"  class='right-inside-panel'>
                      <div>Edit Subscription Type</div>
                      <select-box class=' p-20 ' :index_value=state.addSubscriptionPanel.embargoSubsctiptionType title='Subscription Type' :items="['Unkown','Beta','Full']" @changedi="chanegState('edit-type',$event)" ></select-box>
                      
                      <div class='flex flex-justify-center'><button-s1 class='width-200 ml-20 mr-20 mb-20 ' @click="panelButtonPressed('edit-type')" >Edit Type</button-s1></div>
                  </div>

                  <div v-show="state.subShowPanel == 'add-subscription'"  class='right-inside-panel'>
                      <div>Add Subscription</div>
                      <editbox class='mt-10 p-20' :bind_value=state.addSubscriptionPanel.email title='Venue Email' @changed="chanegState('add-sub-venue-email',$event)" />
                      <select-box class=' p-20 ' :index_value=state.addSubscriptionPanel.subscriptionIndex firstDisabledOptionTitle='select a subscription' title='Subscription' :items=state.addSubscriptionPanel.subscriptions @changedi="chanegState('add-sub-subscription',$event)" ></select-box>
                      <date-box class="p-20 " :date="state.addSubscriptionPanel.trialEndDate" title="Trial end date"  @changed="chanegState('add-sub-trial',$event)" />
                      <div class='panel-error mb-10'> {{state.addSubscriptionPanel.error}} </div>
                      
                      <div class='flex flex-justify-center'><button-s1 class='width-200 ml-20 mr-20 mb-20 ' :onClick="addSubscription" >Add Subscription</button-s1></div>
                  </div>

                  <div v-show="state.subShowPanel == 'include-in-subscription'"  class='right-inside-panel'>
                      <div>Include in Subscription</div>
                      <editbox class='mt-10 p-20' :bind_value=state.addSubscriptionPanel.cusID title='Customer ID' @changed="chanegState('include-in-sub-cus-id',$event)" />
                      <select-box class=' p-20 ' :index_value=state.addSubscriptionPanel.subscriptionIndex firstDisabledOptionTitle='select a subscription' title='Subscription' :items=state.addSubscriptionPanel.subscriptions @changedi="chanegState('add-sub-subscription',$event)" ></select-box>
                      <date-box class="p-20 " :date="state.addSubscriptionPanel.trialEndDate" title="Trial end date"  @changed="chanegState('add-sub-trial',$event)" />
                      <div class='panel-error mb-10'> {{state.addSubscriptionPanel.error}} </div>
                      
                      <div class='flex flex-justify-center'><button-s1 class='width-200 ml-20 mr-20 mb-20 ' :onClick="addSubscription" >Add Subscription</button-s1></div>
                  </div>

                  <div v-show="state.subShowPanel == 'edit-subscription'"  class='right-inside-panel'>
                      <div>Edit Subscription</div>
                      <select-box class=' p-20 ' :index_value=state.addSubscriptionPanel.subscriptionIndex firstDisabledOptionTitle='select a subscription' title='Subscription' :items=state.addSubscriptionPanel.subscriptions @changedi="chanegState('add-sub-subscription',$event)" ></select-box>
                      <date-box class="p-20 " :date="state.addSubscriptionPanel.trialEndDate" title="Trial end date"  @changed="chanegState('add-sub-trial',$event)" />
                      <div class='panel-error mb-10'> {{state.addSubscriptionPanel.error}} </div>
                      <div class='flex flex-justify-center'><button-s1 class='width-200 ml-20 mr-20 mb-20 ' :onClick="editSubscription" >Edit Subscription</button-s1></div>
                  </div>

                  <div v-show="state.subShowPanel == 'delete-subscription'"  class='right-inside-panel'>
                      <div>Delete Subscription</div>
                      <div class='mt-20 mr-20 ml-20'> Are you sure you want to delete this subscription?</div>
                      
                      <div class='panel-error mb-10'> {{state.addSubscriptionPanel.error}} </div>
                      <div class='flex mt-20'>
                        <div class='flex flex-justify-center'><button-s1 class='width-100 ml-20 mr-20 mb-20 ' :onClick="() => {state.subShowPanel=''}" >No</button-s1></div>
                        <div class='flex flex-justify-center'><button-s1 class='width-100 ml-20 mr-20 mb-20 ' :onClick="cancelSubscription" >Yes</button-s1></div>
                      </div>
                  </div>
              </div> -->
        
        </div> 
      </div>

    </div> 
  </div>

</section> 
</template> 

<script>
import { reactive } from 'vue'
import useHook from './requests-hook.js'

export default {

  setup(){
    const state = reactive({
      header_titles : ['Date', 'Venue ids', 'Type', 'Status'],
      lines:[]
    })

    const hokFuncs = useHook(state)
    hokFuncs.getRequests()
    return { state , ...hokFuncs } 
  }
}
</script>

<style scoped>
.right-container {border: 1px solid darkgray; margin:0; border-radius: 5px; padding:20px}
.left-container {border: 1px solid darkgray; margin:0; border-radius: 5px; }
</style>
