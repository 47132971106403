<template>
   <div>
     <canvas id='main-convas' style='height:400px; margin-top:30px'></canvas>
   </div>
</template>

<script>
import Chart from "chart.js";
export default {
    
    props:{
        chart_data:{type:Object, default:function () { return {} }},
        legend:{type:Object, default:function () { return {} }},
        show_background_color:{type:Number, default:1},
    },
    watch: {
        chart_data:{
            handler(new_value) {
                console.log('chart-new-value', new_value['values'][0]);
                // console.log('-------');
                
                this.chartData.labels = new_value['labels'];

                let datasets_values = [];
                new_value['values'].forEach((element,index) => {

                    let dict = {
                                data: element,
                                
                                // 
                                borderColor: this.array_chart_colors[index].line_color,
                                // lineTension: 0,
                                pointBackgroundColor: this.array_chart_colors[index].line_color,
                    };

                    if (this.show_background_color == 1)
                    {
                        dict.backgroundColor = this.array_chart_colors[index].background_color;
                    }
                    else 
                    {
                        dict.fill = false;
                    }
            

                    if (this.legend.length > index)
                    {
                        dict.label = this.legend[index];
                    }
                    datasets_values.push(dict);
                });
                
                this.chartData.datasets = datasets_values;

                document.getElementById('main-convas').style.height = '400px';
                this.chat.update();
            },

        }
    },
    data(){
        return{
            array_chart_colors:[
                {line_color:"#5cddff", background_color:"rgba(224, 248, 255, 0.4)"},
                {line_color:"#660000", background_color:"rgba(224, 248, 255, 0.4)"},
            ],
            chat:null,
            chartType: "line",
            chartData: {
                            labels: ['no label'],
                            datasets: [
                            ],
                        },
            chartOptions: {
                scales: {
                xAxes: [
                    {
                    // stacked: true,
                    gridLines: { display: false },
                    },
                ],
                yAxes: [
                    {
                    gridLines: { display: false },
                    ticks: {
                        stepSize: 1,
                        // callback: function(value, index, values) {
                        // if (value % Math.round(values[0] / 6) == 0) {
                        //     return value;
                        // } else if (value === 0) {
                        //     return value;
                        // }
                        // },
                    },
                    // stacked: true
                    },
                ],
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 2000,
                    easing: "easeInOutQuart",
                },
            },
        }
    },
    mounted(){
        // let {chartType,chartData,chartOptions} = this;
        this.createChart();
    },
    
    methods:{
        createChart(){
            
            
            if (this.legend.length > 0)
            {
                this.chartOptions.legend = {
                        labels: {
                            boxWidth: 10,
                        },
                        position: "top",
                };
            }
            else 
            {
                this.chartOptions.legend = {display:false};
            }
            this.chartConstructor(this.chartType, this.chartData, this.chartOptions);
        },
        chartConstructor(chartType, chartData, chartOptions) {
                const chartElement = document.querySelector("canvas");
                this.chat = new Chart(chartElement, {
                type: chartType,
                data: chartData,
                options: chartOptions,
            });
        },
    }
}
</script>