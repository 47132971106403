<template>
    <section>
         <div>
            <slot></slot>
        </div> 
    </section>

</template>

<style scoped>
section{width:100%;  padding: 20px; overflow: hidden}
div {background: #FFFFFF; padding: 20px; border: 0.5px solid #DBCFD4;  border-radius: 6px; width:100%; max-width:1200px }
/* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15); */
</style>