import { createRouter, createWebHistory } from 'vue-router';
import Login from './pages/Login.vue';
import Home from './pages/Home.vue';
import VenueUser from './pages/VenueUser.vue';
import VenueUserGrowth from './pages/VenueUserGrowth.vue';
import NotificationEntity from './pages/notifications/NotificationEntity.vue';
import NotificationList from './pages/notifications/NotificationList.vue';
import StatisticsOrders from './pages/StatisticsOrders.vue';
import OrderDetails from './pages/statistics/OrderDetails.vue';
import PromocodeGenerate from './pages/PromocodeGenerate.vue';
import Logs from './pages/Logs.vue';
import VenueUserAcquisition from './pages/statistics/VenueUserAcquisition.vue';
import VenueMonthlyGrowth from './pages/statistics/VenueMonthlyGrowth.vue';

import PortalUsers from './pages/portal-users/portal-users.vue';
import PortalUserList from './pages/portal-users/portal-user-list.vue';

import StampcodeNew from './pages/stampcodes/StampcodeNew.vue';
import StampcodeList from './pages/stampcodes/StampcodeList.vue';
import DataBase from './pages/database/DataBase.vue';
import OnlineOrderTest from './pages/tasks/OnlineOrderTest.vue';

import SubscriptionList from './pages/subscriptions/SubscriptionList.vue';
import SubscriptionReport from './pages/subscriptions/SubscriptionReport.vue';
import SubscriptionReportPayment from './pages/subscriptions/SubscriptionReportPayment.vue';
import SubscriptionData from './pages/subscriptions/SubscriptionData.vue';
import requestList from './pages/requests/request-list.vue';


import Lists from './pages/lists/Lists.vue';




import store from './store/index.js';

const router = createRouter({
  history: createWebHistory(), 
    routes:[ 
        {path:'/', component: Home, meta: { loginRequired: true }  },
        {path:'/home', component: Home, meta: { loginRequired: true }  }, 
        {path:'/venues/users', component: VenueUser, meta: { loginRequired: true }  }, 
        {path:'/venues/user/growth', component: VenueUserGrowth, meta: { loginRequired: true }  },  
        {path:'/reports/venue/growth', component: VenueUserGrowth, meta: { loginRequired: true }  }, 

        {path:'/notification/new', component: NotificationEntity, meta: { loginRequired: true }  },  
        {path:'/notification/list', component: NotificationList, meta: { loginRequired: true }  },  
        {path:'/notification/edit/:entityID', component: NotificationEntity, meta: { loginRequired: true }  },  
        

        {path:'/statistics/online-orders', component: StatisticsOrders, meta: { loginRequired: true }  },  
        {path:'/statistics/online-order/:orderid', component: OrderDetails, meta: { loginRequired: true }  },  
        
        {path:'/statistics/venues/monthly-user-acquisition', component: VenueUserAcquisition, meta: { loginRequired: true }  }, 
        {path:'/statistics/venues/monthly-growth', component: VenueMonthlyGrowth, meta: { loginRequired: true }  }, 
        
        {path:'/portal-user/new', component: PortalUsers, meta: { loginRequired: true }  }, 
        {path:'/portal-user/edit/:entityID', component: PortalUsers, meta: { loginRequired: true }  }, 
        {path:'/portal-user/list', component: PortalUserList, meta: { loginRequired: true }  }, 

        {path:'/stampcodes/generate', component: StampcodeNew, meta: { loginRequired: true }  }, 
        {path:'/stampcodes/list', component: StampcodeList, meta: { loginRequired: true }  }, 
        {path:'/stampcodes/edit/:codeid', component: StampcodeNew, meta: { loginRequired: true }  },  

        {path:'/promocodes/generate', component: PromocodeGenerate, meta: { loginRequired: true }  }, 
        {path:'/promocodes/list', component: Lists, meta: { loginRequired: true }  },
        
        {path:'/online-order-test', component: OnlineOrderTest, meta: { loginRequired: true }  },  

        
        {path:'/logs', component: Logs, meta: { loginRequired: true }  },
        {path:'/login', component: Login},
        {path:'/database', component: DataBase, meta: { loginRequired: true }  }, 

        {path:'/subscriptions/list', component: SubscriptionList, meta: { loginRequired: true }  }, 
        {path:'/subscriptions/report', component: SubscriptionReport, meta: { loginRequired: true }  }, 
        {path:'/subscriptions/payments', component: SubscriptionReportPayment, meta: { loginRequired: true }  }, 
        {path:'/subscriptions/stripe-data', component: SubscriptionData, meta: { loginRequired: true }  }, 
        
        {path:'/requests/list', component: requestList, meta: { loginRequired: true }  }, 
 
        // {path:'/users', component: UsersList}
    ]
});

router.beforeEach(function(to,_,next) {
  
  
  if (to.meta.loginRequired && !store.getters.isLoggedin) {
    // console.log(store.getters.isLoggedin); 
    next('/login');
  } else {
    // console.log('ok');
    next();
  }
});


export default router;
