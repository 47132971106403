<template>
<section style='display:flex; flex-direction:column;  '>
  
<page-header>Subscription Report</page-header>
  
    <panel>
      <div class='flex'>
        <select-box class='width-200' style='max-width:200px'  title='Show' 
            :items="['All','Trial','Active Subscription','No Subscription']"
            :index_value=state.showStatus @changedi="filterChanged('show',$event)"/>

        <select-box class='ml-10' style='max-width:200px'  title='Sort by' 
            :items="['ID','Name']"
            :index_value=state.sortBy @changedi="filterChanged('sort',$event)"/>

        <select-box class='ml-10' style='max-width:200px'  title='Show Country' 
            :items=state.showCountry.list
            :index_value=state.showCountry.index @changedi="filterChanged('country',$event)"/>
      </div>
  
      <hr class='mt-10'>
      
      <div class='flex'>
        <select-box class='mt-10' style='max-width:200px'  title='From' 
            :items=state.timeFilter.showList 
            :index_value=state.timeFilter.fromIndex @changedi="filterChanged('from-filter',$event)"/>

        <select-box class='mt-10 ml-10' style='max-width:200px'  title='To' 
            :items=state.timeFilter.showList 
            :index_value=state.timeFilter.toIndex @changedi="filterChanged('to-filter',$event)"/>

        <div style='display:flex; flex-direction:column; margin-left: auto; '>
           <div style=' font-size:14px; margin-top:10px'> Total Venues: {{state.totalVenues}}</div>
           <button-s1  class='width-200  mt-10 mr-10 ' :onClick="csvClicked" > Download CSV</button-s1>
        </div>
       
      </div>

      
    </panel>

   

    
    <div class='page-container flex-11 overflow-hidden' style='display:flex; flex-direction:column;'>
      <div v-if="state.pageLoading" class='ml-20'>loading Stripe informatoin, this might take a while, please wait ... </div>
      <div v-else class='flex overflow-hidden ' style='flex: 1 1; '>
          <div class='overflow-scroll flex-11 left-container mr-10' style='height:100%'  >
        
            <table>
                
                    <tr v-for="(line_item,line_index) in state.table_lines" :key="line_index" @click=lineClicked(line_index)>
                        <td v-for="(item,index_column) in line_item" :key="index_column"   >
                          {{item}}
                        </td>
                    </tr>
              
            </table> 

          </div>
      </div>
    </div>
    
</section>
</template>

<script>
import { reactive } from 'vue'
 
import useSubHook from './SubscriptionHook.js'

//@change="stateValueChanged('sendDate',$event)"
export default {
  components: {
  },
  setup(){
       
        const state = reactive ({
        
        //   arrayVenues:[],
        //   subscriptionsObj: {},
        //   subInfo:[],
        //   subShowPanel:'',
        //   addSubscriptionPanel:{},
        //   trialEndDate:new Date(),
          pageLoading:true,
        //   panelData:{},
        //   savingStatus:'',
        //   showStatus:0,
        //   sortBy:0,
        //   showCountry: {list:['All'], status:'All', index:0}
      
        })
        // const table_header_titles = ['#','Venue #','Name','Country','Plan', 'Status']

        const {getData,csvClicked,
        // lineClicked , refreshSubscriptions , addSubscription , editSubscription, 
            chanegState , filterChanged
            //panelMainButtonClicked , cancelSubscription , filterChanged
        } 
        = useSubHook(state)

        getData()

        return { state , chanegState , filterChanged,csvClicked
        //  , table_header_titles , lineClicked , refreshSubscriptions , editSubscription , 
        // addSubscription , chanegState , panelMainButtonClicked , cancelSubscription, 
        }
  }

}
</script>

<style lang="scss" scoped>

table { border-collapse: collapse; font-size:12px; width:100%}
th, td { height:30px; padding: 0 5px; text-align: left; border-bottom: 1px solid #ddd;}
tr:hover {background-color:#d3e1e3;}

.right-container {border: 1px solid darkgray; margin:0; border-radius: 5px; padding:20px}
.left-container {border: 1px solid darkgray; margin:0; border-radius: 5px; }
.detail-info {
  >div:nth-child(1) {color:darkgray; font-size:12px;}
  >div:nth-child(2) {color:black; font-size:14px; margin-left: 10px;}
}
.right-inside-panel{border: 1px solid darkgray; margin: 10px 0; border-radius: 5px; overflow: hidden;
  >div:nth-child(1) {background:lightgray; font-size:14px; height:40px; padding: 10px}
}
.panel-error {color:red; text-align: center}
</style>