
import axios from 'axios';

export default {
  
  async login(context, params) {
      return context.dispatch('authentication', {
        ...params,
        mode: 'login'
      });
  },
  async authentication({commit}, params) {
    console.log('s',commit);
    
      let login_result = false;
      let url = process.env.VUE_APP_URL+'login';
      console.log(url);
      
      let send_data = {'user_name':params.user_name, 'password':params.password};

      const data = new FormData() 
      data.append('params', JSON.stringify(send_data))
   
      await axios({ 
        method: 'post',
        url: url,
        headers: { 'content-type': 'application/json', 'Accept': 'application/json' },
        data: data
      })
      .then((response) => {
        let received_data =response.data;
        // console.log(received_data );

        if (received_data['token']) 
        {
          login_result = true;

          commit('setUser', {
            token: received_data.token,
            user_id: received_data.user_id
          });
        }
        else console.log('n');
        
      })
      .catch((error) => {
        console.log('e', error);
      });

    
      return login_result;
  },
  autoLogin(context){
    const local_token = localStorage.getItem('token');
    const localU_user_id = localStorage.getItem('user_id');
    if (local_token && localU_user_id){
      context.commit('setUser', {
        token: local_token,
        user_id: localU_user_id
      });
    }
  },
  signout(context){
    context.commit('removeUser');  
  }
};
